import React, { useEffect } from "react";
import CountUp from "react-countup";
import { ReportDataEntry } from "./risk-report/data";

import { ChartFlexboxContainer } from "./scrollytelling-elements";

interface CountUpNumberComponentProps {
  number: number;
  numberWord: string;
  sign: string;
  data: ReportDataEntry;
}
export default function CountUpNumberComponent({
  number,
  sign,
  numberWord,
  className,
  data,
}: CountUpNumberComponentProps & JSX.IntrinsicElements["div"]) {
  useEffect(() => {
    return;
  }, []);

  const clearNumber =
    data === 0
      ? false
      : data.item_id.includes("clear") &&
        " transition-all  opacity-0 mr-[-25%]";

  if (sign === "%") {
    return (
      <div className={"w-fullborder-4 " + className + clearNumber}>
        <div className="w-full text-8xl font-SophiaProBold text-highlight-color">
          <span className="">
            {" "}
            <span className="border-0 ">
              <CountUp duration={1} end={number}></CountUp>
            </span>
            %
          </span>
        </div>
      </div>
    );
  }

  return (
    <ChartFlexboxContainer>
      {/* Has to be a static width because of dynamically growing number */}
      <div className={"w-[270px] " + className + clearNumber}>
        <div className="w-full text-9xl font-SophiaProBold text-highlight-color">
          <span>{sign}</span>
          <span className="absolute">
            <CountUp end={number}></CountUp>
          </span>
        </div>
        <div className="w-full mt-8 text-5xl text-center ">{numberWord}</div>
      </div>
    </ChartFlexboxContainer>
  );
}
