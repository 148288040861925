export type ReportDataEntry =
  | {
      item_id: string;
      number_of_elements?: number;
      largeTitle: string;
      highlightTitle?: string | "";
      highlights: string[];
      paragraphs: string[];
      data: DataEntry[];
    }
  | 0;

const emptyData = {
  Question: "",
  Answer: "",
  Respondents: 0,
  "Percent respondents": "0%",
};

export type DataEntry = {
  Question: string;
  Answer?: string;
  Respondents?: number;
  "Percent respondents"?: string;
  Response?: string;
  "Number respondents after crisis in Ukraine"?: number;
  "Percent respondents after crisis in Ukraine"?: string;
  "Number respondents before crisis in Ukraine"?: number;
  "Percent respondents before crisis in Ukraine"?: string;
  Timing?: string;
};

export const reportData: ReportDataEntry[] = [
  {
    item_id: "clear-open",
    number_of_elements: 100,
    largeTitle: "clear",
    highlightTitle: "",
    highlights: [""],
    paragraphs: [],
    data: [emptyData, emptyData, emptyData, emptyData, emptyData],
  },
  {
    item_id: "clear-bar-open",
    number_of_elements: 100,
    largeTitle: "clear",
    highlightTitle: "",
    highlights: [""],
    paragraphs: [],
    data: [emptyData, emptyData, emptyData, emptyData, emptyData],
  },
  {
    item_id: "waffle-blank",
    number_of_elements: 1600,
    paragraphs: [],
    highlights: ["2nd tier"],
    highlightTitle: "Each square represents fifteen executives",

    largeTitle:
      "Interos surveyed 1,500 global decision makers across multiple industries about the impact of continued supply chain disruption.",
    data: [
      {
        Question:
          "Disruptions in which of the following tiers of your organization’s supply chain have impacted your business operations?",
        Answer: "2nd tier",
        Respondents: 496,
        "Percent respondents": "100%",
      },
    ],
  },

  {
    item_id: "part-3-slide-1-Q14",
    paragraphs: [],
    highlights: [
      "2nd tier",
      "3rd and/or 4th tier",
      "5th and/or 6th tier",
      "7th and/or 8th tier",
      "9th tier and below",
    ],
    largeTitle:
      "Most organizations have experienced supply chain disruptions beyond their Tier 1 suppliers.",
    highlightTitle:
      "Percent of organizations that have experienced supply chain disruptions beyond their Tier 1 suppliers",

    data: [
      // {
      //     "Question": "Disruptions in which of the following tiers of your organization’s supply chain have impacted your business operations?",
      //     "Answer": "1st tier",
      //     "Respondents": 273,
      //     "Percent respondents": "18%"
      // },
      {
        Question:
          "Disruptions in which of the following tiers of your organization’s supply chain have impacted your business operations?",
        Answer: "2nd tier",
        Respondents: 496,
        "Percent respondents": "33%",
      },
      {
        Question:
          "Disruptions in which of the following tiers of your organization’s supply chain have impacted your business operations?",
        Answer: "3rd and/or 4th tier",
        Respondents: 732,
        "Percent respondents": "49%",
      },
      {
        Question:
          "Disruptions in which of the following tiers of your organization’s supply chain have impacted your business operations?",
        Answer: "5th and/or 6th tier",
        Respondents: 508,
        "Percent respondents": "34%",
      },
      {
        Question:
          "Disruptions in which of the following tiers of your organization’s supply chain have impacted your business operations?",
        Answer: "7th and/or 8th tier",
        Respondents: 251,
        "Percent respondents": "17%",
      },
      {
        Question:
          "Disruptions in which of the following tiers of your organization’s supply chain have impacted your business operations?",
        Answer: "9th tier and below",
        Respondents: 53,
        "Percent respondents": "4%",
      },
    ],
  },

  {
    item_id: "part-2-slide-2",
    paragraphs: [],
    highlights: [""],
    largeTitle:
      "Disruptive, high - impact events are a regular occurrence. Companies average three significant supply chain disruptions per year.",
    highlightTitle: "Number of disruptions per year",

    data: [
      {
        Question:
          "Disruptions in which of the following tiers of your organization’s supply chain have impacted your business operations?",
        Answer: "1 Disruption",
        Respondents: 0,
        "Percent respondents": "9%",
      },
      {
        Question:
          "Disruptions in which of the following tiers of your organization’s supply chain have impacted your business operations?",
        Answer: "2 Disruptions",
        Respondents: 0,
        "Percent respondents": "22%",
      },
      {
        Question:
          "Disruptions in which of the following tiers of your organization’s supply chain have impacted your business operations?",
        Answer: "3 to 4 Disruptions",
        Respondents: 0,
        "Percent respondents": "41%",
      },
      {
        Question:
          "Disruptions in which of the following tiers of your organization’s supply chain have impacted your business operations?",
        Answer: "5 to 6 Disruptions",
        Respondents: 0,
        "Percent respondents": "18%",
      },
      {
        Question:
          "Disruptions in which of the following tiers of your organization’s supply chain have impacted your business operations?",
        Answer: "7 to 8 Disruptions",
        Respondents: 0,
        "Percent respondents": "2%",
      },
      {
        Question:
          "Disruptions in which of the following tiers of your organization’s supply chain have impacted your business operations?",
        Answer: "9 or more Disruptions",
        Respondents: 0,
        "Percent respondents": "0%",
      },
    ],
  },
  {
    item_id: "part-2-blank-slide-3",
    number_of_elements: 0,
    paragraphs: [],
    highlights: ["       "],
    highlightTitle: "      ",

    largeTitle:
      "We asked: “In your estimation, what is the annual cost in revenue to your organization as a result of supply chain disruption?”",
    data: [
      {
        Question:
          "Disruptions in which of the following tiers of your organization’s supply chain have impacted your business operations?",
        Answer: "2nd tier",
        Respondents: 1600,
        "Percent respondents": "100%",
      },
    ],
  },
  {
    item_id: "part-1-slide-2-Q2",
    largeTitle:
      "Almost two-thirds of organizations plan to make ‘wholesale changes’ to their supply chain footprints.",
    highlightTitle:
      "plan to make ‘wholesale changes’ to their supply chain footprints",
    number_of_elements: 100,
    paragraphs: [],
    highlights: [
      "To a great extent – we plan to make wholesale changes to our supply chain footprint",
    ],
    data: [
      {
        Question: "Plan to make ‘wholesale changes’",
        Answer:
          "To a great extent – we plan to make wholesale changes to our supply chain footprint",
        Respondents: 961,
        "Percent respondents": "64%",
      },
      {
        Question: "Plan to make ‘wholesale changes’",
        Answer:
          "To some extent – we plan to make small changes, but nothing major to our supply chain footprint",
        Respondents: 504,
        "Percent respondents": "34%",
      },
      {
        Question: "Plan to make ‘wholesale changes’",
        Answer:
          "To no extent – we have no plans to make any changes to our supply chain footprint",
        Respondents: 35,
        "Percent respondents": "2%",
      },
      {
        Question: "Plan to make ‘wholesale changes’",
        Answer: "Don’t know",
        Respondents: 0,
        "Percent respondents": "0%",
      },
    ],
  },

  {
    item_id: "part-1-slide-3-Q6",
    number_of_elements: 100,
    largeTitle:
      "Nearly 9 in 10 executives agree their supply bases are too concentrated in certain geographic locations.",
    highlightTitle:
      "agree their supply bases are too concentrated in certain geographic locations.",
    highlights: ["Strongly agree", "Slightly agree"],
    paragraphs: [],
    data: [
      // {
      //     "Question": "To what extent do you agree with the following statement? \"My organization has too many suppliers concentrated in one area of the world and this is of concern to us",
      //     "Answer": "Combination of \"Strongly agree\" and \"Slightly agree",
      //     "Respondents": 1288,
      //     "Percent respondents": "86%"
      // },
      // {
      //     "Question": "To what extent do you agree with the following statement? \"My organization has too many suppliers concentrated in one area of the world and this is of concern to us",
      //     "Answer": "Combination of \"Strongly disagree\" and \"Slightly disagree",
      //     "Respondents": 212,
      //     "Percent respondents": "14%"
      // },
      {
        Question:
          'To what extent do you agree with the following statement? "My organization has too many suppliers concentrated in one area of the world and this is of concern to us',
        Answer: "Strongly agree",
        Respondents: 534,
        "Percent respondents": "36%",
      },
      {
        Question:
          'To what extent do you agree with the following statement? "My organization has too many suppliers concentrated in one area of the world and this is of concern to us',
        Answer: "Slightly agree",
        Respondents: 754,
        "Percent respondents": "50%",
      },
      {
        Question:
          'To what extent do you agree with the following statement? "My organization has too many suppliers concentrated in one area of the world and this is of concern to us',
        Answer: "Slightly disagree",
        Respondents: 158,
        "Percent respondents": "11%",
      },
      {
        Question:
          'To what extent do you agree with the following statement? "My organization has too many suppliers concentrated in one area of the world and this is of concern to us',
        Answer: "Strongly disagree",
        Respondents: 54,
        "Percent respondents": "4%",
      },
      {
        Question:
          'To what extent do you agree with the following statement? "My organization has too many suppliers concentrated in one area of the world and this is of concern to us',
        Answer: "Don’t know",
        Respondents: 0,
        "Percent respondents": "0%",
      },
    ],
  },
  {
    item_id: "part-1-slide-4-unknown",
    number_of_elements: 100,
    largeTitle:
      "Companies are retreating from global supply chains – half of suppliers are set to be reshored or nearshored.",
    highlightTitle:
      "of suppliers are expected to be nearshored / reshored within 3 years",
    highlights: [
      "51% of suppliers are expected to be nearshored / reshored within 3 years",
    ],
    paragraphs: [],
    data: [
      {
        Question: "",
        Answer:
          "51% of suppliers are expected to be nearshored / reshored within 3 years",
        Respondents: 1600,
        "Percent respondents": "51%",
      },
    ],
  },

  //     Add stat
  // Righthand text: 51% of suppliers are expected to be nearshored / reshored within 3 years.

  // Lefthand Text: Companies are retreating from global supply chains – half of suppliers are set to be reshored or nearshored

  {
    item_id: "Q8-ukraine",
    largeTitle:
      "Geopolitical risk factors have more than doubled in importance since Russia invaded Ukraine.",
    paragraphs: [],
    highlights: [],
    data: [
      {
        Question:
          "Which of the following factors are most important to your organization when evaluating strategic partners/suppliers? Combination of responses ranked first, second and third",
        Answer:
          "Geopolitical (political instability, economic inequality, political rights)",
        Respondents: 95,
        Timing: "Before Russia invaded Ukraine",
      },
      {
        Question:
          "Which of the following factors are most important to your organization when evaluating strategic partners/suppliers? Combination of responses ranked first, second and third",
        Answer:
          "Geopolitical (political instability, economic inequality, political rights)",
        Respondents: 360,
        Timing: "After Russia invaded Ukraine",
      },
    ],
  },
  {
    item_id: "part-2-slide-4-Q16",
    largeTitle:
      "Nearly all respondents said that frequent supply chain disruptions cost their organizations tens of millions of dollars a year.",
    paragraphs: [],
    highlightTitle:
      "say frequent supply chain disruptions cost organizations tens of millions of dollars a year",
    highlights: [
      "say frequent supply chain disruptions cost organizations tens of millions of dollars a year",
    ],
    data: [
      {
        Question:
          "In your estimation, what is the annual cost in revenue to your organization as a result of supply chain disruption?",
        Answer:
          "say frequent supply chain disruptions cost organizations tens of millions of dollars a year",
        Respondents: 1552,
        "Percent respondents": "97%",
      },

      {
        Question: "a",
        Answer: "b",
        Respondents: 48,
        "Percent respondents": "3%",
      },
      // {
      //     "Question": "In your estimation, what is the annual cost in revenue to your organization as a result of supply chain disruption?",
      //     "Answer": "Less than $1 million (USD)",
      //     "Respondents": 0,
      //     "Percent respondents": "0%"
      // },
      // {
      //     "Question": "In your estimation, what is the annual cost in revenue to your organization as a result of supply chain disruption?",
      //     "Answer": "$1 million - $10 million (USD)",
      //     "Respondents": 102,
      //     "Percent respondents": "7%"
      // },
      // {
      //     "Question": "In your estimation, what is the annual cost in revenue to your organization as a result of supply chain disruption?",
      //     "Answer": "$10 million - $25 million (USD)",
      //     "Respondents": 157,
      //     "Percent respondents": "10%"
      // },
      // {
      //     "Question": "In your estimation, what is the annual cost in revenue to your organization as a result of supply chain disruption?",
      //     "Answer": "$25 million - $50 million (USD)",
      //     "Respondents": 204,
      //     "Percent respondents": "14%"
      // },
      // {
      //     "Question": "In your estimation, what is the annual cost in revenue to your organization as a result of supply chain disruption?",
      //     "Answer": "$50 million - $100 million (USD)",
      //     "Respondents": 319,
      //     "Percent respondents": "21%"
      // },
      // {
      //     "Question": "In your estimation, what is the annual cost in revenue to your organization as a result of supply chain disruption?",
      //     "Answer": "$100 million- $250 million (USD)",
      //     "Respondents": 370,
      //     "Percent respondents": "25%"
      // },
      // {
      //     "Question": "In your estimation, what is the annual cost in revenue to your organization as a result of supply chain disruption?",
      //     "Answer": "$250 million - $500 million (USD)",
      //     "Respondents": 208,
      //     "Percent respondents": "14%"
      // },
      // {
      //     "Question": "In your estimation, what is the annual cost in revenue to your organization as a result of supply chain disruption?",
      //     "Answer": "$500 million - $1 billion (USD)",
      //     "Respondents": 89,
      //     "Percent respondents": "6%"
      // },
      // {
      //     "Question": "In your estimation, what is the annual cost in revenue to your organization as a result of supply chain disruption?",
      //     "Answer": "More than $1 billion (USD)",
      //     "Respondents": 5,
      //     "Percent respondents": "0%"
      // },
      // {
      //     "Question": "In your estimation, what is the annual cost in revenue to your organization as a result of supply chain disruption?",
      //     "Answer": "My organization is not losing any revenue as a result of global supply chain disruption",
      //     "Respondents": 38,
      //     "Percent respondents": "3%"
      // },
      // {
      //     "Question": "In your estimation, what is the annual cost in revenue to your organization as a result of supply chain disruption?",
      //     "Answer": "Don’t know",
      //     "Respondents": 8,
      //     "Percent respondents": "1%"
      // }
    ],
  },
  {
    item_id: "part-2-slide-5",
    largeTitle: "The average annual cost of these disruptions is $182 million.",
    paragraphs: [],
    highlightTitle:
      "say frequent supply chain disruptions cost organizations tens of millions of dollars a year",
    highlights: [
      "say frequent supply chain disruptions cost organizations tens of millions of dollars a year",
    ],
    data: [],
  },

  {
    item_id: "part-3-slide-3-Q4",
    largeTitle:
      "Only 11% of organizations say they monitor supplier risks on a continuous basis.",
    paragraphs: [],
    highlightTitle: " monitor supplier risks on a continuous basis",
    highlights: ["We monitor supplier risk on a continuous basis"],
    data: [
      {
        Question:
          "How frequently is your organization monitoring supplier risk as part of your organization’s risk analysis?",
        Answer: "We monitor supplier risk on a continuous basis",
        Respondents: 168,
        "Percent respondents": "11%",
      },
      {
        Question:
          "How frequently is your organization monitoring supplier risk as part of your organization’s risk analysis?",
        Answer: "",
        Respondents: 1332,
        "Percent respondents": "88%",
      },
      // {
      //   Question:
      //     "How frequently is your organization monitoring supplier risk as part of your organization’s risk analysis?",
      //   Answer: "Weekly",
      //   Respondents: 241,
      //   "Percent respondents": "16%",
      // },
      // {
      //   Question:
      //     "How frequently is your organization monitoring supplier risk as part of your organization’s risk analysis?",
      //   Answer: "Monthly",
      //   Respondents: 516,
      //   "Percent respondents": "35%",
      // },
      // {
      //   Question:
      //     "How frequently is your organization monitoring supplier risk as part of your organization’s risk analysis?",
      //   Answer: "Quarterly",
      //   Respondents: 375,
      //   "Percent respondents": "25%",
      // },
      // {
      //   Question:
      //     "How frequently is your organization monitoring supplier risk as part of your organization’s risk analysis?",
      //   Answer: "Every six months",
      //   Respondents: 111,
      //   "Percent respondents": "7%",
      // },
      // {
      //   Question:
      //     "How frequently is your organization monitoring supplier risk as part of your organization’s risk analysis?",
      //   Answer: "Annually",
      //   Respondents: 54,
      //   "Percent respondents": "4%",
      // },
      // {
      //   Question:
      //     "How frequently is your organization monitoring supplier risk as part of your organization’s risk analysis?",
      //   Answer:
      //     "We monitor supplier risk on an ad-hoc basis/when a problem arises",
      //   Respondents: 27,
      //   "Percent respondents": "2%",
      // },
      // {
      //   Question:
      //     "How frequently is your organization monitoring supplier risk as part of your organization’s risk analysis?",
      //   Answer: "Don’t know",
      //   Respondents: 0,
      //   "Percent respondents": "0%",
      // },
    ],
  },
  {
    item_id: "Q23-technology-enables",
    largeTitle:
      "Technology enables organizations to mitigate supply chain risk and gain a competitive advantage.",
    highlightTitle:
      "Organizations say technology enables them to mitigate supply chain risk and gain a competitive advantage",

    paragraphs: [],
    highlights: [
      "Greater ability to analyze/mitigate risk",
      "Competitive advantage over rival organizations",
      "Visibility across many different types of events",
      "Lower costs (e.g. downtime, cost to revenue etc.)",
      "Reduced reputational damage",
    ],
    data: [
      // { "Question": "In your opinion, what are/would be the greatest benefits to your organization investing in a supply chain solution that can analyze risk across multiple categories?" },
      {
        Question:
          "In your opinion, what are/would be the greatest benefits to your organization investing in a supply chain solution that can analyze risk across multiple categories?",
        Answer: "Greater ability to analyze/mitigate risk",
        Respondents: 864,
        "Percent respondents": "58%",
      },
      {
        Question:
          "In your opinion, what are/would be the greatest benefits to your organization investing in a supply chain solution that can analyze risk across multiple categories?",
        Answer: "Competitive advantage over rival organizations",
        Respondents: 813,
        "Percent respondents": "54%",
      },
      {
        Question:
          "In your opinion, what are/would be the greatest benefits to your organization investing in a supply chain solution that can analyze risk across multiple categories?",
        Answer: "Visibility across many different types of events",
        Respondents: 664,
        "Percent respondents": "44%",
      },
      {
        Question:
          "In your opinion, what are/would be the greatest benefits to your organization investing in a supply chain solution that can analyze risk across multiple categories?",
        Answer: "Lower costs (e.g. downtime, cost to revenue etc.)",
        Respondents: 641,
        "Percent respondents": "43%",
      },
      {
        Question:
          "In your opinion, what are/would be the greatest benefits to your organization investing in a supply chain solution that can analyze risk across multiple categories?",
        Answer: "Reduced reputational damage",
        Respondents: 363,
        "Percent respondents": "24%",
      },
    ],
  },

  {
    item_id: "part-4-slide-1-blank",
    largeTitle: `<span class='lg:text-3xl'>We asked: “Does your organization plan on leveraging automated/intelligent solutions to gain visibility into interdependencies into your supply chain?”</span>`,
    highlightTitle:
      "use intelligent supply chain visibility solutions or plan to implement them soon",

    paragraphs: [],
    highlights: [
      '" and "We have technology in place, and are currently in the process of implementing it',
    ],
    data: [
      {
        Question:
          "Does your organization plan on leveraging automated/intelligent solutions to gain visibility into interdependencies into your supply chain?",
        Answer:
          'Combination of "We have technology in place to do this" and "We have technology in place, and are currently in the process of implementing it',
        Respondents: 1600,
        "Percent respondents": "100%",
      },
      // },
      // {
      //   Question:
      //     "Does your organization plan on leveraging automated/intelligent solutions to gain visibility into interdependencies into your supply chain?",
      //   Answer:
      //     "We do not have technology in place, but have plans to introduce it within the next 6 months",
      //   Respondents: 310,
      //   "Percent respondents": "21%",
      // },
      // {
      //   Question:
      //     "Does your organization plan on leveraging automated/intelligent solutions to gain visibility into interdependencies into your supply chain?",
      //   Answer: "We already have technology in place to do this",
      //   Respondents: 291,
      //   "Percent respondents": "19%",
      // },
      // {
      //   Question:
      //     "Does your organization plan on leveraging automated/intelligent solutions to gain visibility into interdependencies into your supply chain?",
      //   Answer:
      //     "We already have technology, and are currently in the process of implementing it",
      //   Respondents: 734,
      //   "Percent respondents": "49%",
      // },

      // {
      //   Question:
      //     "Does your organization plan on leveraging automated/intelligent solutions to gain visibility into interdependencies into your supply chain?",
      //   Answer:
      //     "We do not have the technology in place, but have plans to introduce it within the next 6-12 months",
      //   Respondents: 105,
      //   "Percent respondents": "7%",
      // },
      // {
      //   Question:
      //     "Does your organization plan on leveraging automated/intelligent solutions to gain visibility into interdependencies into your supply chain?",
      //   Answer:
      //     "We do not have the technology in place, but have plans to introduce it beyond the next 12 months",
      //   Respondents: 40,
      //   "Percent respondents": "3%",
      // },
      // {
      //   Question:
      //     "Does your organization plan on leveraging automated/intelligent solutions to gain visibility into interdependencies into your supply chain?",
      //   Answer:
      //     "We do not have the technology in place, and have no plans to introduce it",
      //   Respondents: 18,
      //   "Percent respondents": "1%",
      // },
      // {
      //   Question:
      //     "Does your organization plan on leveraging automated/intelligent solutions to gain visibility into interdependencies into your supply chain?",
      //   Answer: "Don’t know",
      //   Respondents: 2,
      //   "Percent respondents": "0%",
      // },
    ],
  },
  {
    item_id: "part-4-slide-1-Q27",
    largeTitle:
      "Less than a fifth use intelligent supply chain visibility solutions – but most plan to implement them soon.",
    highlightTitle:
      "use intelligent supply chain visibility solutions or plan to implement them soon",

    paragraphs: [],
    highlights: [
      'Combination of "We have technology in place to do this" and "We have technology in place, and are currently in the process of implementing it',
    ],
    data: [
      {
        Question:
          "Does your organization plan on leveraging automated/intelligent solutions to gain visibility into interdependencies into your supply chain?",
        Answer:
          'Combination of "We have technology in place to do this" and "We have technology in place, and are currently in the process of implementing it',
        Respondents: 1025,
        "Percent respondents": "68%",
      },
      {
        Question:
          "Does your organization plan on leveraging automated/intelligent solutions to gain visibility into interdependencies into your supply chain?",
        Answer:
          'Combination of "We do not have technology in place, but have plans to introduce it within the next 6 months ", "We do not have the technology in place, but have plans to introduce it within the next 6-12 months", "We do not have the technology in place, but have plans to introduce it beyond the next 12 months" and "We do not have the technology in place, and have no plans to introduce it',
        Respondents: 473,
        "Percent respondents": "32%",
      },
      // {
      //   Question:
      //     "Does your organization plan on leveraging automated/intelligent solutions to gain visibility into interdependencies into your supply chain?",
      //   Answer:
      //     "We do not have technology in place, but have plans to introduce it within the next 6 months",
      //   Respondents: 310,
      //   "Percent respondents": "21%",
      // },
      // {
      //   Question:
      //     "Does your organization plan on leveraging automated/intelligent solutions to gain visibility into interdependencies into your supply chain?",
      //   Answer: "We already have technology in place to do this",
      //   Respondents: 291,
      //   "Percent respondents": "19%",
      // },
      // {
      //   Question:
      //     "Does your organization plan on leveraging automated/intelligent solutions to gain visibility into interdependencies into your supply chain?",
      //   Answer:
      //     "We already have technology, and are currently in the process of implementing it",
      //   Respondents: 734,
      //   "Percent respondents": "49%",
      // },

      // {
      //   Question:
      //     "Does your organization plan on leveraging automated/intelligent solutions to gain visibility into interdependencies into your supply chain?",
      //   Answer:
      //     "We do not have the technology in place, but have plans to introduce it within the next 6-12 months",
      //   Respondents: 105,
      //   "Percent respondents": "7%",
      // },
      // {
      //   Question:
      //     "Does your organization plan on leveraging automated/intelligent solutions to gain visibility into interdependencies into your supply chain?",
      //   Answer:
      //     "We do not have the technology in place, but have plans to introduce it beyond the next 12 months",
      //   Respondents: 40,
      //   "Percent respondents": "3%",
      // },
      // {
      //   Question:
      //     "Does your organization plan on leveraging automated/intelligent solutions to gain visibility into interdependencies into your supply chain?",
      //   Answer:
      //     "We do not have the technology in place, and have no plans to introduce it",
      //   Respondents: 18,
      //   "Percent respondents": "1%",
      // },
      // {
      //   Question:
      //     "Does your organization plan on leveraging automated/intelligent solutions to gain visibility into interdependencies into your supply chain?",
      //   Answer: "Don’t know",
      //   Respondents: 2,
      //   "Percent respondents": "0%",
      // },
    ],
  },

  {
    item_id: "Q8-ukraine-before-after",
    largeTitle:
      "Geopolitical risk factors have more than doubled in importance since Russia invaded Ukraine.",
    highlightTitle:
      "After Russia invaded the Ukraine, geopolitical risk factors increased by ",

    paragraphs: [],
    highlights: [
      "Geopolitical (political instability, economic inequality, political rights)",
    ],
    data: [
      {
        Question:
          "Which of the following factors are most important to your organization when evaluating strategic partners/suppliers?",
        Response:
          "ESG – Environmental (e.g. climate change), Governance (e.g. counterfeit exports), Social (e.g. modern slavery)",
        "Number respondents after crisis in Ukraine": 48,
        "Percent respondents after crisis in Ukraine": "57%",
        "Number respondents before crisis in Ukraine": 841,
        "Percent respondents before crisis in Ukraine": "56%",
      },
      {
        Question:
          "Which of the following factors are most important to your organization when evaluating strategic partners/suppliers?",
        Response:
          "Geopolitical (political instability, economic inequality, political rights)",
        "Number respondents after crisis in Ukraine": 47,
        "Percent respondents after crisis in Ukraine": "56%",
        "Number respondents before crisis in Ukraine": 360,
        "Percent respondents before crisis in Ukraine": "24%",
      },
      {
        Question:
          "Which of the following factors are most important to your organization when evaluating strategic partners/suppliers?",
        Response: "Cyber (e.g. data loss, hijacking, phishing)",
        "Number respondents after crisis in Ukraine": 44,
        "Percent respondents after crisis in Ukraine": "52%",
        "Number respondents before crisis in Ukraine": 889,
        "Percent respondents before crisis in Ukraine": "1%",
      },
      {
        Question:
          "Which of the following factors are most important to your organization when evaluating strategic partners/suppliers?",
        Response:
          "Operations (e.g. infrastructure, natural disaster, healthcare capacity)",
        "Number respondents after crisis in Ukraine": 37,
        "Percent respondents after crisis in Ukraine": "44%",
        "Number respondents before crisis in Ukraine": 830,
        "Percent respondents before crisis in Ukraine": "55%",
      },
      {
        Question:
          "Which of the following factors are most important to your organization when evaluating strategic partners/suppliers?",
        Response: "Finance (e.g. liquidity, profitability, solvency)",
        "Number respondents after crisis in Ukraine": 35,
        "Percent respondents after crisis in Ukraine": "42%",
        "Number respondents before crisis in Ukraine": 887,
        "Percent respondents before crisis in Ukraine": "59%",
      },
      {
        Question:
          "Which of the following factors are most important to your organization when evaluating strategic partners/suppliers?",
        Response:
          "Restrictions (e.g. denied persons, state sponsors of terrorism, financial sanctions)",
        "Number respondents after crisis in Ukraine": 35,
        "Percent respondents after crisis in Ukraine": "42%",
        "Number respondents before crisis in Ukraine": 672,
        "Percent respondents before crisis in Ukraine": "45%",
      },
      {
        Question:
          "Which of the following factors are most important to your organization when evaluating strategic partners/suppliers?",
        Response:
          "There are no factors that are important to my organization when evaluating our strategic partners/suppliers",
        "Number respondents after crisis in Ukraine": 1,
        "Percent respondents after crisis in Ukraine": "1%",
        "Number respondents before crisis in Ukraine": 7,
        "Percent respondents before crisis in Ukraine": "0%",
      },
      {
        Question:
          "Which of the following factors are most important to your organization when evaluating strategic partners/suppliers?",
        Response: "Don't know",
        "Number respondents after crisis in Ukraine": 1,
        "Percent respondents after crisis in Ukraine": "1%",
        "Number respondents before crisis in Ukraine": 0,
        "Percent respondents before crisis in Ukraine": "0%",
      },
    ],
  },

  {
    item_id: "clear-donut",
    number_of_elements: 100,
    largeTitle: "clear",
    highlightTitle: "",
    highlights: [""],
    paragraphs: [],
    data: [],
  },

  {
    item_id: "clear-bar",
    number_of_elements: 100,
    largeTitle: "clear",
    highlightTitle: "",
    highlights: [""],
    paragraphs: [],
    data: [],
  },

  {
    item_id: "clear-number",
    number_of_elements: 100,
    largeTitle: "clear",
    highlightTitle: "",
    highlights: [""],
    paragraphs: [],
    data: [],
  },

  {
    item_id: "clear",
    number_of_elements: 100,
    largeTitle: "clear",
    highlightTitle: "",
    highlights: [""],
    paragraphs: [],
    data: [],
  },
];

export const headerText = {
  title: "Resilience 2022",
  subtitle: "The Interos Annual Global Supply Chain Report",
  paragraphs: [
    {
      text: `In early 2022, Interos surveyed 1500 global leaders on the impact of continued supply chain disruption and their plans to act on it. The results showed just how massive the problem truly is, and how technology rests at the center of many leaders’ plans for the future.`,
    },
  ],
};

export const footerText = {
  title: "Conclusion",

  subtitle: "Frequent and Damaging Supply Chain Disruptions Are No Longer Rare",

  paragraphs: [
    {
      text: `Organizations globally recognize that current risk practices limit their ability to counteract supply chain shocks and intend to take action by reconfiguring their supply chains and adopting technology solutions that facilitate information sharing and collaboration.`,
    },

    {
      text: `They must do so quickly, if we are to avoid further global supply chain chaos.`,
    },

    {
      text: `To see more findings from Resilience 2022, read our whitepaper.</a>`,
      buttonLink:
        "https://www.interos.ai/resources/resilience-2022-supply-chain-whitepaper/",
      buttonText: "Read our Whitepaper",
    },

    {
      text: `To learn more about how Interos can help your organization achieve operational resilience, contact us.`,
      buttonLink: "http://www.interos.ai/",
      buttonText: "contact us here",
    },
  ],
};
