import * as React from "react";

const fullColorLogo = (props: JSX.IntrinsicElements["svg"]) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260.97 64.43" {...props}>
    <defs>
      <style>{".cls-1{fill:#060c27}.cls-2{fill:#1d54ef}"}</style>
    </defs>
    <g id="Layer_1" data-name="Layer 1">
      <g id="Artwork_2" data-name="Artwork 2">
        <g id="b">
          <path
            className="cls-1"
            d="M76 13a4.91 4.91 0 1 1 4.91 4.91A4.91 4.91 0 0 1 76 13M76.99 23.29h7.66v29.92h-7.66zM90.69 23.29h7l.65 3.53h.48c1.8-2.34 4.31-4.13 8.8-4.13 7.12 0 11.67 4.55 11.67 12.39v18.13h-7.66V36.64c0-4.91-2.81-7.31-6.4-7.31s-6.88 2.34-6.88 7.67v16.21h-7.66ZM128.46 45.07V29.33h-6.4v-6h2c3.65 0 5.09-1.32 5.09-4.55v-3.63h6.94v8.14H144v6h-7.84v14.77c0 1.67.42 3.71 4 3.71a12.26 12.26 0 0 0 2.93-.42v5.8a23.07 23.07 0 0 1-5.26.66c-8.38 0-9.34-5.44-9.34-8.74M180.17 23.29h7l.71 4.25h.48c1.86-3 3.89-4.85 8.92-4.85h2.15v7.66a19.51 19.51 0 0 0-3.23-.24c-6.58 0-8.32 4.85-8.32 9.16v13.94h-7.66V23.29ZM201.12 38.19c0-9.22 6.82-15.5 15.8-15.5s15.8 6.34 15.8 15.5-6.72 15.62-15.8 15.62-15.8-6.4-15.8-15.62m24 0c0-5-3.3-8.86-8.2-8.86s-8.14 3.89-8.14 8.86 3.23 9 8.14 9 8.2-4 8.2-9M236.07 43.64h7.36c.42 3.23 2.82 4.48 5.87 4.48 2.33 0 4.37-.89 4.37-3s-2-3.23-5.27-4.07l-2.81-.71c-4-1-8.55-2.58-8.55-8.44s5.26-9.16 11.18-9.16c8.08 0 11.79 4.25 12.21 9.16h-7.36c-.3-2.16-2-3.48-4.78-3.48-2.34 0-3.95 1.14-3.95 3.06 0 2.09 2 2.63 4.9 3.41l2.82.72c5.2 1.31 8.91 3.71 8.91 9.33 0 6.17-5.26 8.92-11.61 8.92-8.44 0-12.86-4-13.28-10.17M168.54 44.7c-1 2.08-3.22 3.2-6.21 3.2-4.62 0-7.4-3.08-7.69-7.34h21.3c.06-.83.12-1.48.12-2.19 0-10.59-6.75-15.2-14-15.2-8.16 0-14.91 5.91-14.91 15.68 0 9.11 6.39 15.09 15.21 15.09 5.87 0 10.26-2.36 12.46-6.3l-6.25-2.94ZM162 29.2a6.44 6.44 0 0 1 6.81 6.27h-14.12A7.15 7.15 0 0 1 162 29.2"
          />
          <path
            className="cls-2"
            d="M3.84 14.8a6.27 6.27 0 1 1 6.27 6.27 6.28 6.28 0 0 1-6.27-6.27M46.1 32.08a13.87 13.87 0 0 0-19.38-12.6.83.83 0 0 0-.47.53 17 17 0 0 1-7.37 9.26.71.71 0 0 0-.32.45 13.68 13.68 0 0 0-.22 2.5v11.84a.6.6 0 0 0 .6.6h6.44a.6.6 0 0 0 .6-.6V32.22a6.25 6.25 0 0 1 12.5-.14V44a.6.6 0 0 0 .6.61h6.42a.61.61 0 0 0 .6-.61V32.09Z"
          />
          <path
            className="cls-2"
            d="M33.43 0A31.86 31.86 0 0 0 22 1.67a.5.5 0 0 0-.2.84 17 17 0 0 1 3.76 5.26.92.92 0 0 0 1.05.52 24.58 24.58 0 1 1-19 23.93.86.86 0 0 0-.61-.85 16.74 16.74 0 0 1-6.13-2.48.47.47 0 0 0-.73.36c-.09 1-.14 2-.14 3a32.25 32.25 0 0 0 32.22 32.18 32.52 32.52 0 0 0 32.19-33.52A32.29 32.29 0 0 0 33.43 0Z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default fullColorLogo;
