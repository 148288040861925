import React, { useState, useRef, useMemo } from "react";
import { Helmet } from "react-helmet";
import { reportData, ReportDataEntry } from "../components/risk-report/data";
import "../index.css";
import {
  ParagraphBlock,
  SingleParagraph,
  FeaturedImageFlex,
  FeaturedImageInnerText,
} from "../components/risk-report/scrollytelling-text";
import {
  SeperatorLine,
  ScrollySectionWaffle,
  ScrollySectionBarDonutWaffle,
  tailwindStyles,
} from "../components/scrollytelling-elements";
import { AnimatedImageContainer } from "../components/scrollytelling-elements";
import { ButtonSVG } from "../components/scrollytelling-elements";
import FullColorLogo from "../images/Interos-full-color-logo";
import { CookieConsent } from "../components/consent/cookie-consent";
import { useCookie } from "../utils/use-cookie";

const numberOfElements = 100;
const numberedArray = Array.from({ length: numberOfElements }, (_v, i) => i);

export type StepEnterEvent = {
  element: HTMLElement;
  data: ReportDataEntry;
  direction: ScrollDirection;
  entry: IntersectionObserverEntry;
};

export type StepExitEvent = StepEnterEvent;

export type StepProgressEvent = StepEnterEvent & {
  progress: number;
};

export enum ScrollDirection {
  UP = "up",
  DOWN = "down",
}

function ScrollytellingPage() {
  const [cookie] = useCookie("gatsby-gdpr-google-analytics");
  const showCookieConsent = useMemo(() => cookie === undefined, [cookie]);
  const [cookieConsentInteracted, setCookieConsentInteracted] = useState(false);

  const fullData = reportData;
  const [data, setData] = useState<ReportDataEntry>(0);
  const steps = reportData;
  const [scrollDirection, setScrollDirection] = useState<ScrollDirection>(
    ScrollDirection.DOWN
  );
  const [, setProgress] = useState(0);

  const onStepEnter = ({ data, direction }: StepEnterEvent) => {
    if (data !== 0 && data.item_id === "clear") {
      setData(0);
    }
    setData(data);
    setScrollDirection(direction);
  };

  const onStepProgress = ({ progress }: StepProgressEvent) => {
    setProgress(progress);
  };

  const scrollToRefIntro = useRef<HTMLDivElement>(null);
  const scrollToTop = useRef<HTMLDivElement>(null);

  const scrollToRefSectionOne = useRef<HTMLDivElement>(null);
  const scrollToRefSectionTwo = useRef<HTMLDivElement>(null);
  const scrollToRefSectionThree = useRef<HTMLDivElement>(null);
  const scrollToRefSectionFour = useRef<HTMLDivElement>(null);
  const onScrollerClick = (ref: React.RefObject<HTMLDivElement>) =>
    ref.current?.scrollIntoView({ behavior: "smooth" });
  const addedMargin = " mt-[-80vh] ";
  return (
    <>
      <Helmet title="2022 Survey Report">
        <meta name="robots" content="noindex" />
        <meta
          property="og:title"
          content="Explore our Animated Supply Chain Survey"
        />
        <meta
          property="og:description"
          content="Want to know the real cost of supply chain disruption and what businesses intend to do about it? Explore Interos' interactive version of Resilience 2022, our annual global supply chain report."
        />
        <meta
          property="og:image"
          content="https://insights.interos.ai/scrollytelling-link-preview.png"
        />
        <meta
          property="og:url"
          content="https://insights.interos.ai/2022-survey-report/"
        />
        <meta property="og:type" content="website" />
      </Helmet>
      {showCookieConsent && !cookieConsentInteracted && (
        <CookieConsent onInteraction={() => setCookieConsentInteracted(true)} />
      )}
      <div className="font-normal" ref={scrollToTop}>
        <AnimatedImageContainer
          title=""
          image="HeaderImage"
          fromTo={{ from: 0, to: 500 }}
        ></AnimatedImageContainer>
        <div className="flex flex-wrap content-center justify-center w-full border-2 lg:justify-end lg:w-1/2 text-lighter-bg">
          {/* Inner layout flex */}
          <div className="flex flex-wrap items-center justify-center w-full max-w-xl border-2 lg:w-full text-lighter-bg">
            <button
              onClick={() => onScrollerClick(scrollToRefIntro)}
              aria-label="Scroll to next section"
              className={`${tailwindStyles.button_dark} mt-[-30px] `}
            >
              <ButtonSVG />
            </button>
          </div>
        </div>{" "}
        {allContent()}
        <div className="pb-4 bg-darker-background">
          <AnimatedImageContainer
            title=""
            image="FooterImage"
            fromTo={{ from: 0, to: 500 }}
          ></AnimatedImageContainer>
        </div>
        <FeaturedImageFlex>
          <FeaturedImageInnerText>
            <div className="absolute w-11/12 max-w-xl text-xl font lg:max-w-5xl xl:max-w-6xl">
              <div className={`pt-8 ${tailwindStyles.subtitle_text}`}>
                About Interos
              </div>
              Interos is the operational resilience company — reinventing how
              companies manage their supply chains and business relationships —
              through our breakthrough SaaS platform that uses artificial
              intelligence to model and transform the ecosystems of complex
              businesses into a living global map, down to any single supplier,
              anywhere. For more information visit us as{" "}
              <a href="https://www.interos.ai" className="font-black">
                www.interos.ai
              </a>
              <a className="w-[150px] mt-10" href="https://www.interos.ai">
                . <FullColorLogo className="w-[150px] mt-10" width={150} />
              </a>
              <div className="flex flex-wrap items-center justify-center w-full m-4 lg:w-full text-lighter-bg">
                <button
                  onClick={() => onScrollerClick(scrollToTop)}
                  className={tailwindStyles.button_light}
                  aria-label="Scroll to top"
                >
                  <ButtonSVG orient={"up"} />
                </button>
              </div>
            </div>
          </FeaturedImageInnerText>
        </FeaturedImageFlex>
      </div>
    </>
  );

  // --------------------------------------------
  // --------------------------------------------
  // --------------------------------------------
  // --------------------------------------------
  // --------------------------------------------

  function allContent() {
    return (
      <div ref={scrollToRefIntro}>
        <div className="float-right w-full"></div>
        {/* <article title="Global Supply Chains are Being Reconfigured in Response to Disruptive Events"> */}
        <AnimatedImageContainer
          title="Global Supply Chains are Being Reconfigured in Response to Disruptive Events"
          part="Part One"
          image="ImageOne"
          fromTo={{ from: -500, to: 0 }}
        ></AnimatedImageContainer>
        <ParagraphBlock className="mt-8">
          <SingleParagraph>
            Concerns about an overconcentration of supply in certain
            geographies, combined with recent experience of global shortages and
            growing lead times, has strengthened the case for local sourcing and
            manufacturing.
          </SingleParagraph>
          <SingleParagraph>
            This includes everything from personal protective equipment and
            life-saving drugs to essential foods and silicon chips. For the past
            three decades, supply chain operating models have often dictated
            that such products be manufactured in low-cost markets with
            plentiful labor – leading operations to expand worldwide. But as
            wage gaps have closed and logistics problems and other risks have
            mounted, calls to “reshore” production back to home countries have
            grown.
          </SingleParagraph>
        </ParagraphBlock>
        <SeperatorLine />
        <div className="flex flex-wrap justify-center w-full ">
          <button
            onClick={() => onScrollerClick(scrollToRefSectionOne)}
            className={tailwindStyles.button_light}
            aria-label="Scroll to next section"
          >
            <ButtonSVG></ButtonSVG>
          </button>
        </div>
        <div ref={scrollToRefSectionOne}>
          <ScrollySectionWaffle
            scrollDirection={scrollDirection}
            setData={setData}
            addedMargin={addedMargin}
            waffleArray={numberedArray}
            numberOfElements={numberOfElements}
            selectedIds={[
              "waffle-blank",
              "part-1-slide-3-Q6",
              "part-1-slide-2-Q2",
              "part-1-slide-4-unknown",
            ]}
            onStepEnter={onStepEnter}
            steps={steps}
            data={data}
            onStepProgress={onStepProgress}
            onStepExit={() => {
              return undefined;
            }}
          ></ScrollySectionWaffle>
        </div>
        {/* </article> */}
        {/* -------------------PART 2------------------- */}
        <div className={"bg-med-light-bg"}>
          <AnimatedImageContainer
            title="Supply Chain Disruptions are Frequent, Expensive and Often Hidden from View"
            part="Part Two"
            image="ImageTwo"
            fromTo={{ from: -500, to: 0 }}
          ></AnimatedImageContainer>
          <ParagraphBlock className="pt-10 pb-0 mb-0 ">
            <SingleParagraph>
              Global supply chains are in a state of flux as organizations plan
              major changes in a post-COVID era.
            </SingleParagraph>
            <SingleParagraph>
              {" "}
              With so many notable interruptions happening in a relatively short
              space of time, it is important to understand the wider impacts on
              businesses.
            </SingleParagraph>
            <SingleParagraph>
              Disruptive, high-impact events are a regular occurrence. Companies
              average three significant supply chain disruptions per year.
            </SingleParagraph>
          </ParagraphBlock>
          <SeperatorLine />
          <div className="flex flex-wrap justify-center w-full ">
            <button
              onClick={() => onScrollerClick(scrollToRefSectionTwo)}
              className={tailwindStyles.button_light}
              aria-label="Scroll to next section"
            >
              <ButtonSVG />
            </button>
          </div>
          {/* Remove margin from entire section */}
          <div className="" ref={scrollToRefSectionTwo}>
            <ScrollySectionBarDonutWaffle
              addedMargin={addedMargin}
              selectedIds={[
                "part-2-blank-slide-3",
                "part-2-slide-2",
                // "clear-bar-open",
                "part-2-slide-4-Q16",
                "part-2-slide-5",
                "clear-number",
                "clear",
              ]}
              fullData={fullData}
              onStepProgress={onStepProgress}
            ></ScrollySectionBarDonutWaffle>
          </div>
        </div>
        {/* -------------------PART 3------------------- */}
        <div className={" "}>
          <AnimatedImageContainer
            title="Supply Chain Risk Practices Require Further Improvement
"
            part="Part Three"
            image="ImageThree"
            fromTo={{ from: -500, to: 0 }}
          ></AnimatedImageContainer>
          <div className="flex flex-wrap justify-center w-full ">
            <button
              onClick={() => onScrollerClick(scrollToRefSectionThree)}
              className={tailwindStyles.button_light}
              aria-label="Scroll to next section"
            >
              <ButtonSVG />
            </button>
          </div>
          <div ref={scrollToRefSectionThree}></div>
          {
            <ScrollySectionBarDonutWaffle
              addedMargin={addedMargin}
              selectedIds={[
                "clear-bar-open",
                "part-3-slide-1-blank",

                "part-3-slide-1-Q14",
                "part-3-slide-3-Q4",
                "clear-donut",
              ]}
              fullData={fullData}
              onStepProgress={onStepProgress}
            ></ScrollySectionBarDonutWaffle>
          }
        </div>
        <SeperatorLine />
        <ParagraphBlock className="mt-8">
          <SingleParagraph>
            In addition to the above, our research found that a majority of
            organizations would only know about a disruptive event if it came
            from Tiers 1 or 2.
          </SingleParagraph>

          <SingleParagraph>
            Despite an increasing emphasis among organizations on reconfiguring
            their supply chains in response to disruptive events, significant
            disruptions are frequent, remain financially damaging and occur in
            places many organizations lack awareness of.
          </SingleParagraph>

          <SingleParagraph>
            The fact that many companies lack insights into their extended
            supply chain calls into question the supply chain risk monitoring
            practices they currently have in place and suggests they still have
            some way to go in their journey to become operationally resilient.
          </SingleParagraph>
        </ParagraphBlock>
        <div className="h-8"></div>
        {/* -------------------PART 4------------------- */}
        <div className={"bg-med-light-bg"}>
          <AnimatedImageContainer
            title="Technology Has a Vital Role to Play in Managing Risk Proactively"
            part="Part Four"
            image="ImageFour"
            fromTo={{ from: -500, to: 0 }}
          ></AnimatedImageContainer>

          <div className="flex flex-wrap justify-center w-full ">
            <button
              onClick={() => onScrollerClick(scrollToRefSectionFour)}
              className={tailwindStyles.button_light}
              aria-label="Scroll to next section"
            >
              <ButtonSVG />
            </button>
          </div>
          <div ref={scrollToRefSectionFour}></div>
          <ScrollySectionBarDonutWaffle
            addedMargin={addedMargin}
            selectedIds={[
              "part-4-slide-1-blank",
              "clear-bar-open",
              "Q23-technology-enables",
              "part-4-slide-1-Q27",
              "clear-donut",
              "clear",
            ]}
            fullData={fullData}
            onStepProgress={onStepProgress}
          ></ScrollySectionBarDonutWaffle>
        </div>
      </div>
    );
  }
}

export default ScrollytellingPage;
