import React, { useState, useRef, useMemo, useEffect } from "react";
import { Scrollama, Step } from "react-scrollama";
import { DonutComponent } from "./donut-visx";
import { WaffleComponent } from "./waffle";
import { BarComponent } from "./bar";
import { AnimatedImage } from "./animated-image";
import { ImageOne } from "../components/images/image-1/image-1";
import { ImageTwo } from "../components/images/image-2/image-2";
import { ImageThree } from "../components/images/image-3/image-3";
import { ImageFour } from "../components/images/image-4/image-4";
import CountUpNumberComponent from "./count-up-number";
import { HeaderImage } from "../components/images/header-image/header-image";
import {
  FeaturedImageInnerText,
  SideTextTypeOne,
  FeaturedImageFlex,
} from "./risk-report/scrollytelling-text";

import {
  FacebookLogo,
  TwitterLogo,
  LinkedInLogo,
} from "../images/social-logos";

import DownloadSvg from "../images/download";

import { useInView } from "react-intersection-observer";
import {
  ScrollDirection,
  StepEnterEvent,
  StepExitEvent,
  StepProgressEvent,
} from "../pages";
import {
  DataEntry,
  ReportDataEntry,
  headerText,
  footerText,
} from "./risk-report/data";

export function Social() {
  return (
    <div className="h-[50px] mb-8 mt-12">
      <a
        className="float-left mr-4"
        href={"https://mobile.twitter.com/interos"}
        title="Twitter link"
        rel="noreferrer"
      >
        <TwitterLogo width={50} />
      </a>

      <a
        className="float-left mr-4"
        href={"https://www.linkedin.com/company/interos-inc"}
        title="Linkedin link"
        rel="noreferrer"
      >
        <LinkedInLogo width={50} />
      </a>

      <a
        className="float-left mr-4"
        href={"https://www.facebook.com/InterosInc/"}
        title="Facebook link"
        rel="noreferrer"
      >
        <FacebookLogo width={50} />
      </a>
    </div>
  );
}

export const windowWidth =
  typeof window != "undefined" ? window.innerWidth : 1000;

export const windowHeight =
  typeof window != "undefined" ? window.innerHeight : 1000;

export const isVisiblyRendered = typeof window != "undefined";

interface ScrollySectionWaffleProps {
  scrollDirection: ScrollDirection;
  addedMargin: string;
  data: ReportDataEntry;
  steps: ReportDataEntry[];
  selectedIds: string[];
  numberOfElements: number;
  waffleArray: number[];
  onStepProgress: (event: StepProgressEvent) => void;
  onStepEnter: (event: StepEnterEvent) => void;
  onStepExit: (event: StepExitEvent) => void;
  setData: (data: ReportDataEntry) => void;
}
export function ScrollySectionWaffle({
  scrollDirection,
  addedMargin = "",
  waffleArray,
  numberOfElements,
  selectedIds,
  className,
  onStepEnter,
  onStepExit,
  steps,
  data,
  onStepProgress,
}: ScrollySectionWaffleProps & JSX.IntrinsicElements["div"]) {
  return (
    <ScrollySectionContainer
      scrollDirection={scrollDirection}
      addedMargin={addedMargin}
      selectedIds={selectedIds}
      className={className}
      onStepEnter={onStepEnter}
      onStepExit={onStepExit}
      steps={steps}
      data={data}
      onStepProgress={onStepProgress}
    >
      {useMemo(() => {
        if (data !== 0 && selectedIds.includes(data.item_id)) {
          return (
            <WaffleComponent
              progress={steps}
              waffleArray={waffleArray}
              numberOfElements={numberOfElements}
              data={data}
              width={0}
              height={0}
            ></WaffleComponent>
          );
        }
      }, [data, windowWidth])}
    </ScrollySectionContainer>
  );
}

interface ScrollSectionBarStaticProps {
  selectedId: string;
  data: ReportDataEntry[];
}
export function ScrollySectionBarStatic({
  selectedId,
  data,
  className,
}: ScrollSectionBarStaticProps & JSX.IntrinsicElements["div"]) {
  const filteredData = data.filter(
    (d: ReportDataEntry) => d !== 0 && d.item_id === selectedId
  );
  const { ref } = useInView({
    /* Optional options */
    rootMargin: "100% 0%",
    threshold: 0,
    triggerOnce: false,
  });

  // inView && setData(0)
  if (filteredData[0])
    return (
      <div className="" ref={ref}>
        {
          <BarComponent
            data={filteredData[0]}
            width={500}
            height={500}
            numberOfElements={0}
            waffleArray={[]}
            className={className}
          ></BarComponent>
        }
      </div>
    );

  return <></>;
}

export function ChartFlexboxContainer({
  children,
  className,
}: JSX.IntrinsicElements["div"]) {
  const visibileStyles =
    " opacity-1 visible h-[100vh] lg:w-1/2 w-full flex flex-wrap items-center content-center justify-center justify-items-center" +
    className;

  return <div className={visibileStyles}>{children}</div>;
}

interface ScrollySectionBarDonutWaffleProps {
  selectedIds: string[];
  fullData: ReportDataEntry[];
  addedMargin: string;
  onStepProgress: (event: StepProgressEvent) => void;
}
export function ScrollySectionBarDonutWaffle({
  selectedIds,
  className,
  fullData,
  onStepProgress,
  addedMargin = "",
}: ScrollySectionBarDonutWaffleProps & JSX.IntrinsicElements["div"]) {
  const donutIds = [
    "part-4-slide-1-blank",
    "part-3-slide-1-blank",
    "part-3-slide-3-Q4",
    "part-4-slide-1-Q27",
    "part-2-slide-4-Q16",
    "part-2-blank-slide-3",
    "part-2-slide-4",
    "clear-donut",
  ];
  const barIds = [
    "clear-open",
    "clear-bar-open",
    "Q23-technology-enables",
    "part-3-slide-1-Q14",
    "part-2-slide-2",
    "clear-bar",
  ];
  const numberIds = ["part-2-slide-5", "clear-number"];

  const resetObject = {
    item_id: "clear",
    number_of_elements: 100,
    largeTitle: "clear",
    highlightTitle: "",
    highlights: [""],
    paragraphs: [],
    data: [],
  };

  const [data, setData] = useState<ReportDataEntry>(0);
  const [scrollDirection, setScrollDirection] = useState<ScrollDirection>(
    ScrollDirection.DOWN
  );

  const steps = fullData;

  const onStepEnter = ({ data, direction }: StepEnterEvent) => {
    if (data !== 0 && data.item_id === "clear") {
      setData(0);
    }
    setData(data);
    setScrollDirection(direction);
  };

  const onStepExit = ({ data, direction }: StepExitEvent) => {
    if (direction === "up" && data === steps[0]) {
      setData(0);
    } else if (direction === "down" && data === steps[0]) {
      setData(0);
    } else if (data !== 0 && data.item_id === "clear") {
      setData(0);
    }
  };

  const clearData =
    data !== 0 && (data.item_id === "clear" || data.item_id === "clear-open");

  useEffect(() => {
    setData(0);
  }, [clearData]);

  return (
    <ScrollySectionContainer
      scrollDirection={scrollDirection}
      onStepProgress={onStepProgress}
      addedMargin={addedMargin}
      selectedIds={selectedIds}
      className={className}
      onStepEnter={onStepEnter}
      onStepExit={onStepExit}
      steps={steps}
      data={data}
    >
      {data !== 0 && donutIds.includes(data.item_id) && (
        <DonutComponent
          data={data}
          width={50}
          height={50}
          highlights={[]}
          annotationTitle={""}
          chartTitle={""}
        ></DonutComponent>
      )}

      {data !== 0 && barIds.includes(data.item_id) && (
        <BarComponent
          data={barIds.includes(data.item_id) ? data : resetObject}
          width={500}
          height={500}
          numberOfElements={0}
          waffleArray={[]}
        ></BarComponent>
      )}

      {data !== 0 && numberIds.includes(data.item_id) && (
        <CountUpNumberComponent
          data={data}
          // className={ numberIds.includes(data.item_id) ? " transition-all opacity-1 w-full" : " transition-all opacity-0 w-0 lg:w-0" }
          number={182}
          sign={"$"}
          numberWord={"Million"}
        ></CountUpNumberComponent>
      )}
      {/* Clear condition in side text component */}
    </ScrollySectionContainer>
  );
}

interface FeaturedImageProps {
  inViewRef: (node?: Element | null | undefined) => void;
  containerRef: React.RefObject<HTMLDivElement>;
  hasLogo?: boolean;
  hasSocial?: boolean;
  title: string;
  subtitle: string;
  paragraphs: {
    text: string;
    buttonLink?: string;
    buttonText?: string;
  }[];
}
export function FeaturedImage({
  inViewRef,
  containerRef,
  hasLogo = false,
  title = "",
  subtitle = "",
  paragraphs = [],
}: FeaturedImageProps) {
  {
    /* TODO - make these styles reusable */
  }

  return (
    <div
      className="text text-med-light-bg bg-darker-background"
      ref={inViewRef}
    >
      <FeaturedImageFlex>
        <FeaturedImageInnerText>
          <a href="https://www.interos.ai" title="Link to Interos.ai">
            {" "}
            {hasLogo && (
              <div className="pb-8">
                <SvgLogo></SvgLogo>
              </div>
            )}
          </a>
          {hasLogo ? (
            <div className="relative pb-8 text-7xl lg:text-[5.5rem] font-SophiaProBold">
              {title}
            </div>
          ) : (
            <div className={tailwindStyles.title_footer + " pt-8"}>{title}</div>
          )}
          <div className={tailwindStyles.subtitle_text}>{subtitle}</div>
          {paragraphs.map((p, i) => (
            <>
              <div className="pb-8">
                <div
                  dangerouslySetInnerHTML={{ __html: p.text }}
                  key={i}
                  className="text-xl lg:leading-normal"
                ></div>
                {p.buttonLink && p.buttonText && (
                  <div
                    className={`${tailwindStyles.button_dark} uppercase hover:cursor-pointer text-sm font-semibold mt-4 pt-3 pb-3 h-11 w-fit `}
                  >
                    <a href={p.buttonLink} aria-title={p.buttonText}>
                      <div className="relative float-left">{p.buttonText}</div>
                      <div className="relative float-left top-[-3px] pl-1 pr-4">
                        <ButtonSVG orient="side"></ButtonSVG>
                      </div>
                    </a>
                  </div>
                )}
              </div>
            </>
          ))}
          {hasLogo && (
            <div className="pb-12 mt-8 text-xl">
              <a
                href="https://www.interos.ai/resources/resilience-2022-supply-chain-whitepaper"
                target="_blank"
                title="Download full whitepaper. Opens in new tab."
                rel="noreferrer"
                className="absolute z-[100] hover:text-button-highlight fill-lighter-bg hover:fill-button-highlight underline-offset-4 text-lighter-bg"
              >
                <div className="float-left"> Download the Full Whitepaper</div>
                <DownloadSvg className="float-left w-4 pt-1 ml-2 " />{" "}
              </a>
            </div>
          )}
          {!hasLogo && <Social />}
        </FeaturedImageInnerText>
        <div
          className={
            hasLogo
              ? "bg-darker-background w-full lg:w-1/2 h-[90vh]  flex-row overflow-clip"
              : "bg-darker-background  w-full lg:w-1/2 h-[100vh]  flex-row"
          }
        >
          <div
            ref={containerRef}
            className=" absolute overflow-hidden mt-[-40px] w-full  lg:w-1/2 h-[100vh]"
          >
            <AnimatedImage
              animationType={"slideIn"}
              fromTo={{ from: 500, to: 0 }}
            >
              <HeaderImage containerRef={containerRef}></HeaderImage>
            </AnimatedImage>
          </div>
        </div>
      </FeaturedImageFlex>
    </div>
  );
}

interface AnimatedImageContainerProps {
  title: string;
  part?: string;
  image: string;
  fromTo: {
    from: number;
    to: number;
  };
}
export function AnimatedImageContainer({
  title,
  image,
  part,
  fromTo,
}: AnimatedImageContainerProps) {
  const { ref } = useInView({
    /* Optional options */
    rootMargin: "75% 0%",
    threshold: 1,
  });
  const containerRef = useRef<HTMLDivElement>(null);

  if (image === "HeaderImage") {
    return (
      <FeaturedImage
        inViewRef={ref}
        containerRef={containerRef}
        hasLogo={true}
        title={headerText.title}
        subtitle={headerText.subtitle}
        paragraphs={headerText.paragraphs}
      ></FeaturedImage>
    );
  }

  if (image === "FooterImage") {
    return (
      <FeaturedImage
        inViewRef={ref}
        containerRef={containerRef}
        hasLogo={false}
        hasSocial={true}
        title={footerText.title}
        subtitle={footerText.subtitle}
        paragraphs={footerText.paragraphs}
      ></FeaturedImage>
    );
  }

  return (
    <div
      ref={ref}
      className={"border-0 flex-row justify-center align-middle flex-wrap "}
    >
      <div className="border-0 max-w-[800px] m-auto ">
        {part && (
          <div className="tracking-normal	font-SophiaProBold uppercase p-4 pt-24 text-xl max-w-[500px] text-highlight-color font-bold ">
            {part}
          </div>
        )}
        <div className="p-4 text-4xl ">{title}</div>
      </div>

      <div
        ref={containerRef}
        className={
          "border-0 p-0 max-w-[700px] m-auto  overflow-hidden min-h-[50vh]  max-h-[1000px]"
        }
      >
        <AnimatedImage animationType={"slideIn"} fromTo={fromTo}>
          {image === "ImageOne" && (
            <ImageOne containerRef={containerRef}></ImageOne>
          )}
          {image === "ImageTwo" && (
            <ImageTwo containerRef={containerRef}></ImageTwo>
          )}
          {image === "ImageThree" && (
            <ImageThree containerRef={containerRef}></ImageThree>
          )}
          {image === "ImageFour" && <ImageFour></ImageFour>}
        </AnimatedImage>
      </div>
      <SeperatorLine></SeperatorLine>
    </div>
  );
}

export function SeperatorLine() {
  return (
    <div className="flex flex-wrap justify-center w-full h-2 mt-4 align-middle">
      <div className="w-1/2 h-[1px] bg-lowlight-color-heavy m-0  "></div>
    </div>
  );
}

interface ScrollySectionContainerProps {
  scrollDirection?: ScrollDirection;
  selectedIds: string[];
  steps: ReportDataEntry[];
  data: ReportDataEntry;
  addedMargin: string;
  onStepProgress: (event: StepProgressEvent) => void;
  onStepEnter: (event: StepEnterEvent) => void;
  onStepExit: (event: StepExitEvent) => void;
}
export function ScrollySectionContainer({
  scrollDirection,
  onStepProgress,
  selectedIds,
  onStepEnter,
  onStepExit,
  steps,
  data,
  children,
  addedMargin = "",
}: ScrollySectionContainerProps & JSX.IntrinsicElements["div"]) {
  return (
    <div className={"h-full"}>
      <div
        className={
          "graphic-container m-0 top-0 flex flex-nowrap min-w-full min-h-[100vh]  sticky align-middle justify-center lg:justify-end"
        }
      >
        {children}
      </div>

      <div className={"w-full lg:w-1/2 " + addedMargin}>
        {isVisiblyRendered && (
          <Scrollama
            onStepProgress={onStepProgress}
            onStepEnter={onStepEnter}
            onStepExit={onStepExit}
            offset={0.75}
          >
            {steps.map((value) => {
              const isVisible =
                value !== 0 && data !== 0 && value.item_id === data.item_id;
              const background = isVisible
                ? `rgba(255,255,255,0)`
                : "rgba(255,255,255,0)";

              const color =
                isVisible || scrollDirection === "up"
                  ? "text-bright-text"
                  : "text-lowlight-color-heavy ";

              if (value !== 0 && selectedIds.includes(value.item_id)) {
                return (
                  <Step data={value} key={value.item_id}>
                    <div className={"p-0 relative z-20"} style={{ background }}>
                      <div className={color + " flex justify-center "}>
                        {
                          <SideTextTypeOne
                            paragraphs={value.paragraphs}
                            largeTitle={value.largeTitle}
                          />
                        }
                      </div>
                    </div>
                  </Step>
                );
              }

              return <></>;
            })}
          </Scrollama>
        )}
      </div>
    </div>
  );
}

export function getPercentHighlights(
  data: ReportDataEntry,
  numberOfElements: number
) {
  const highlightedData =
    data === 0
      ? []
      : data.data.filter((d: DataEntry) =>
          d.Answer !== undefined ? data.highlights.includes(d.Answer) : false
        );

  const percentHighlights = () => {
    return highlightedData.map((d) => {
      if (d["Percent respondents"]) {
        return +d["Percent respondents"].replace("%", "");
      }
    });
  };

  const sumPercentHighlights = percentHighlights().reduce((a, b) => {
    return (a ?? 0) + (b ?? 0);
  }, 0);

  const percentToHighlight = sumPercentHighlights;
  const percentOfTotal = sumPercentHighlights
    ? (sumPercentHighlights / 100) * numberOfElements
    : 0;

  return {
    percentHighlights,
    sumPercentHighlights,
    percentToHighlight,
    percentOfTotal,
    highlightedData,
  };
}

export const barLayoutData = ["Q8-ukraine-before-after"];

export function checkBarLayoutHighlights(data: ReportDataEntry) {
  const isBarLayout = data !== 0 && barLayoutData.includes(data.item_id);

  return {
    isBarLayout,
    barLayoutData: data,
  };
}

export const useContainerDimensions = (
  myRef: React.RefObject<HTMLDivElement>
) => {
  const getDimensions = () => ({
    width: myRef.current?.offsetWidth,
    height: myRef.current?.offsetHeight,
  });

  const [dimensions, setDimensions] = useState<{
    width: number | undefined;
    height: number | undefined;
  }>({ width: 0, height: 0 });

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions());
    };

    if (myRef.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [myRef]);

  return dimensions;
};

interface AnimatedAnnotationProps {
  percentToHighlight: number;
  waffleWidth: number;
}
export function AnimatedAnnotation({
  percentToHighlight,
  waffleWidth,
}: AnimatedAnnotationProps) {
  const height = (percentToHighlight / 100) * waffleWidth - 50;

  return (
    <g
      className="annotation stroke-medium-color"
      transform={`translate(${-20} ${waffleWidth - height - 31})`}
    >
      <rect height={height + 75} width={1}></rect>
      <g className="annotation" transform={`translate(${0} ${height + 75})`}>
        <rect height={1} width={15}></rect>
      </g>
      <g className="annotation" transform={`translate(${0} ${height}`}>
        <rect height={1} width={15}></rect>
      </g>
    </g>
  );
}
//up, down, side,

interface ButtonSVGProps {
  orient?: "up" | "down" | "side";
}
export function ButtonSVG({ orient = "down" }: ButtonSVGProps) {
  return (
    <>
      <div className="float-left mr-1 font-medium text-center ">
        {orient === "up" ? "Back to Top" : orient === "side" ? "" : "Scroll"}
      </div>
      <svg
        width={30}
        height={30}
        className={
          orient === "side"
            ? "float-left stroke-4 mt-[-2px] absolute"
            : "float-left stroke-4 mt-[3px] animate-bounce"
        }
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 22.19 26.5"
      >
        <g
          className={
            orient === "up"
              ? "origin-center rotate-180"
              : orient === "side"
              ? "origin-center -rotate-90"
              : ""
          }
        >
          <path
            fill="none"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="2"
            d="M11.1 2.6L11.1 23.25"
          ></path>
          <path
            fill="none"
            strokeWidth="2"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M18.89 16.11L11.1 23.9 3.31 16.11"
          ></path>
        </g>
      </svg>
    </>
  );
}

export function SvgLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 260.97 64.43"
      className=" fill-lighter-bg hover:fill-button-highlight  w-[150px]"
    >
      <g data-name="Artwork 2">
        <g>
          <path
            d="M80.25 16.21a4.91 4.91 0 114.91 4.91 4.9 4.9 0 01-4.91-4.91"
            transform="translate(-4.28 -3.26)"
          ></path>
          <path d="M76.99 23.29H84.64999999999999V53.21H76.99z"></path>
          <path
            d="M95 26.54h7l.66 3.53h.48c1.79-2.33 4.31-4.12 8.79-4.12 7.13 0 11.67 4.54 11.67 12.38v18.14h-7.66V39.89c0-4.91-2.81-7.3-6.4-7.3s-6.88 2.33-6.88 7.66v16.22H95zM132.74 48.33V32.59h-6.4v-6h2c3.65 0 5.08-1.32 5.08-4.55v-3.63h6.95v8.14h7.84v6h-7.84v14.76c0 1.68.42 3.71 4 3.71a12.41 12.41 0 002.93-.42v5.81a23.17 23.17 0 01-5.27.66c-8.38 0-9.34-5.45-9.34-8.74M184.46 26.54h6.94l.72 4.25h.47c1.86-3 3.9-4.84 8.92-4.84h2.16v7.66a19.63 19.63 0 00-3.24-.24c-6.58 0-8.31 4.85-8.31 9.15v13.95h-7.66V26.54zM205.4 41.45c0-9.22 6.83-15.5 15.8-15.5s15.8 6.34 15.8 15.5-6.76 15.62-15.8 15.62-15.8-6.41-15.8-15.62m24 0c0-5-3.29-8.86-8.2-8.86s-8.14 3.89-8.14 8.86 3.24 9 8.14 9 8.2-4 8.2-9M240.36 46.89h7.36c.42 3.23 2.81 4.49 5.86 4.49 2.34 0 4.37-.89 4.37-3.05s-2-3.23-5.26-4.07l-2.81-.72c-4-1-8.56-2.57-8.56-8.44s5.26-9.1 11.19-9.1c8.08 0 11.79 4.25 12.21 9.15h-7.36c-.3-2.15-2-3.47-4.79-3.47-2.33 0-3.95 1.14-3.95 3.05 0 2.1 2 2.64 4.91 3.41l2.81.72c5.21 1.32 8.92 3.71 8.92 9.34 0 6.16-5.27 8.92-11.61 8.92-8.44 0-12.87-4-13.29-10.18M172.82 48c-1 2.09-3.22 3.2-6.21 3.2-4.61 0-7.4-3.07-7.69-7.33h21.3c.06-.83.12-1.48.12-2.19 0-10.59-6.75-15.21-14-15.21-8.16 0-14.91 5.92-14.91 15.68 0 9.12 6.39 15.09 15.21 15.09 5.88 0 10.26-2.36 12.46-6.3L172.82 48zm-6.51-15.49a6.44 6.44 0 016.81 6.27H159a7.13 7.13 0 017.33-6.27M8.12 18.06a6.28 6.28 0 116.27 6.27 6.28 6.28 0 01-6.27-6.27M50.39 35.34A13.91 13.91 0 0036.5 21.6a13.75 13.75 0 00-5.5 1.13.89.89 0 00-.48.54 17 17 0 01-7.37 9.25.66.66 0 00-.31.45 13.76 13.76 0 00-.23 2.5v11.84a.61.61 0 00.61.61h6.43a.6.6 0 00.6-.61V35.47a6.25 6.25 0 0112.5-.13v11.91a.6.6 0 00.6.6h6.43a.6.6 0 00.61-.6V35.48a.71.71 0 000-.14z"
            transform="translate(-4.28 -3.26)"
          ></path>
          <path
            d="M37.71 3.28a32.15 32.15 0 00-11.44 1.64.51.51 0 00-.19.85A17 17 0 0129.83 11a.94.94 0 001.06.52 24.93 24.93 0 015.71-.65 24.59 24.59 0 11-24.68 24.6.84.84 0 00-.68-.84 16.81 16.81 0 01-6.08-2.49.48.48 0 00-.74.36c-.09 1-.14 2-.14 3A32.22 32.22 0 1037.71 3.28z"
            transform="translate(-4.28 -3.26)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export const tailwindStyles = {
  subtitle_text: "pb-8 text-3xl lg:pl-0 lg:pr-0 ",
  title_footer:
    "pb-4 text-xl tracking-wide uppercase lg:pl-0 lg:pr-0 font-SophiaProBold ",
  button_dark:
    "pt-4 pb-4 pl-6 pr-6 text-2xl z-[100] hover:bg-button-highlight hover:text-darker-background hover:stroke-darker-background stroke-lighter-bg border-lighter-bg rounded-full text-lighter-bg bg-highlight-color",

  button_light:
    "p-2 mt-5 text-xl font-bold rounded-full hover:text-button-highlight hover:outline-0 outline-2 outline-l-medium-color absolutep-4 text-darker-background stroke-darker-background hover:stroke-button-highlight ",
};
