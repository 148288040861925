import React, { useRef } from "react";
import Pie, { ProvidedProps, PieArcDatum } from "@visx/shape/lib/shapes/Pie";
import CountUpNumberComponent from "./count-up-number";

import { scaleOrdinal } from "@visx/scale";
import { Group } from "@visx/group";
import { GradientPinkBlue } from "@visx/gradient";
import letterFrequency, {
  LetterFrequency,
} from "@visx/mock-data/lib/mocks/letterFrequency";
import { animated, useTransition, interpolate } from "react-spring";

import {
  getPercentHighlights,
  useContainerDimensions,
  ChartFlexboxContainer,
} from "../components/scrollytelling-elements";
import { DataEntry, ReportDataEntry } from "./risk-report/data";

const letters: LetterFrequency[] = letterFrequency.slice(0, 4);

scaleOrdinal({
  domain: letters.map((l) => l.letter),
  range: [
    "rgba(93,30,91,1)",
    "rgba(85, 66, 255,1)",
    "rgba(93,30,91,0.6)",
    "rgba(93,30,91,0.4)",
  ],
});

const defaultMargin = { top: 20, right: 20, bottom: 20, left: 8 };

export type PieProps = {
  width: number;
  height: number;
  margin?: typeof defaultMargin;
  animate?: boolean;
  data: ReportDataEntry;
  highlights: [];
  annotationTitle: string;
  chartTitle: string;
};

export function DonutComponent({
  data,
  margin = defaultMargin,
  animate = true,
}: PieProps) {
  const componentRef = useRef<HTMLDivElement>(null);

  const { width, height } = useContainerDimensions(componentRef);

  const highlights = (data && data.highlights) as string[];

  data &&
    data.data.sort((a, b) =>
      (b.Answer as string).localeCompare(a.Answer as string)
    );

  function getClass(name: string) {
    if (highlights.includes(name)) return "fill-highlight-color";
    return "fill-lowlight-color-light";
  }

  const innerWidth = width as number;
  const innerHeight = height as number;
  const radius = Math.min(innerWidth, innerHeight) / 2.2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const donutThickness = innerWidth < 500 ? 28 : 52;
  const { percentToHighlight } = getPercentHighlights(data, 1);
  return (
    <ChartFlexboxContainer>
      <div
        ref={componentRef}
        style={{ top: centerY - radius / 3 }}
        className="absolute w-full h-[100vh] text-xl text-center lg:w-6/12 max-w-[500px] text-medium-color text-slate-600"
      >
        <div className="w-full font-bold text-7xl text-highlight-bright-color font-SophiaProBold">
          {data &&
          !data.item_id.includes("blank") &&
          !data.largeTitle.includes("We Asked") ? (
            <CountUpNumberComponent
              number={percentToHighlight as number}
              numberWord=""
              sign={"%"}
              data={data}
            ></CountUpNumberComponent>
          ) : (
            <div className="pt-4 text-9xl">?</div>
          )}
        </div>

        <div
          className="m-auto "
          style={{ top: 1000, width: radius * 2 - donutThickness * 2 - 24 }}
        >{`${
          data && !data.item_id.includes("blank") ? data.highlightTitle : ""
        }`}</div>
      </div>

      <svg
        // opacity={data.item_id.includes("blank") && 0.2}
        className="border-0"
        width={width}
        height={height}
        role="img"
      >
        <title>{`${percentToHighlight}% ${
          data && data.highlightTitle
        } `}</title>

        <GradientPinkBlue id="visx-pie-gradient" />

        <Group top={centerY + margin.top} left={centerX}>
          <Pie
            data={(data && data.data) as DataEntry[]}
            className=""
            pieValue={(data) => {
              return data.Respondents as number;
            }}
            pieSortValues={() => -1}
            outerRadius={radius}
            innerRadius={radius - donutThickness}
            cornerRadius={3}
            padAngle={0.005}
          >
            {(pie) => {
              return (
                <AnimatedPie
                  {...pie}
                  animate={animate}
                  getKey={(data) => data.value.toString()}
                  getClass={(data) => {
                    return getClass(data.data.Answer as string);
                  }}
                ></AnimatedPie>
              );
            }}
          </Pie>
        </Group>
        {animate && (
          <text
            textAnchor="end"
            x={(width as number) - 16}
            y={(height as number) - 16}
            fill="rgba(255,255,255,0)"
            fontSize={11}
            fontWeight={300}
            pointerEvents="none"
            key={Math.random()}
          >
            Click segments to update
          </text>
        )}
      </svg>
    </ChartFlexboxContainer>
  );
}

// react-spring transition definitions
type AnimatedStyles = { startAngle: number; endAngle: number; opacity: number };

const fromLeaveTransition = ({ endAngle }: PieArcDatum<unknown>) => ({
  // enter from 360° if end angle is > 180°
  startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  opacity: 0,
});
const enterUpdateTransition = ({
  startAngle,
  endAngle,
}: PieArcDatum<unknown>) => ({
  startAngle,
  endAngle,
  opacity: 1,
});

type AnimatedPieProps<Datum> = ProvidedProps<Datum> & {
  animate?: boolean;
  getKey: (d: PieArcDatum<Datum>) => string;
  getClass: (d: PieArcDatum<Datum>) => string;
};

function AnimatedPie<Datum>({
  animate,
  arcs,
  path,
  getKey,
  getClass,
}: AnimatedPieProps<Datum>) {
  const transitions = useTransition<PieArcDatum<Datum>, AnimatedStyles>(arcs, {
    from: animate ? fromLeaveTransition : enterUpdateTransition,
    enter: enterUpdateTransition,
    update: enterUpdateTransition,
    leave: animate ? fromLeaveTransition : enterUpdateTransition,
    keys: getKey,
  });

  return transitions((props, arc, { key }) => {
    return (
      <g key={key}>
        <animated.path
          // compute interpolated path d attribute from intermediate angle values
          d={interpolate(
            [props.startAngle, props.endAngle],
            (startAngle, endAngle) =>
              path({
                ...arc,
                startAngle,
                endAngle,
              })
          )}
          className={getClass(arc)}
        />
      </g>
    );
  });
}
