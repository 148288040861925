import * as React from "react";

export const FacebookLogo = (props: JSX.IntrinsicElements["svg"]) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55.5 55.5" {...props}>
    <g data-name="Layer 1">
      <path
        d="M55.5 27.75a27.75 27.75 0 1 0-32.09 27.41V35.77h-7v-8h7v-6.13c0-7 4.15-10.8 10.48-10.8a42.94 42.94 0 0 1 6.22.54v6.83h-3.5c-3.45 0-4.52 2.14-4.52 4.33v5.21h7.69l-1.23 8h-6.46v19.41A27.76 27.76 0 0 0 55.5 27.75Z"
        style={{
          fill: "#fff",
        }}
      />
      <path
        d="m38.55 35.77 1.23-8h-7.69v-5.23c0-2.19 1.07-4.33 4.52-4.33h3.5v-6.83a42.94 42.94 0 0 0-6.22-.54c-6.33 0-10.48 3.84-10.48 10.8v6.11h-7v8h7v19.41a27.87 27.87 0 0 0 8.68 0V35.77Z"
        style={{
          fill: "#0b133a",
        }}
      />
    </g>
  </svg>
);

export const TwitterLogo = (props: JSX.IntrinsicElements["svg"]) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58.55 58.55" {...props}>
    <g data-name="Layer 1">
      <circle
        cx={29.28}
        cy={29.28}
        r={29.28}
        style={{
          fill: "#fff",
        }}
      />
      <path
        d="M44 21.72v1c0 10-7.65 21.63-21.63 21.63a21.46 21.46 0 0 1-11.65-3.4 18.13 18.13 0 0 0 1.81.11 15.26 15.26 0 0 0 9.47-3.3 7.6 7.6 0 0 1-7.1-5.28 7.51 7.51 0 0 0 3.43-.13 7.59 7.59 0 0 1-6.1-7.45v-.1a7.61 7.61 0 0 0 3.45 1 7.61 7.61 0 0 1-2.35-10.15A21.58 21.58 0 0 0 29 23.55a7.61 7.61 0 0 1 13-6.93 15.26 15.26 0 0 0 4.83-1.85A7.68 7.68 0 0 1 43.44 19a15.35 15.35 0 0 0 4.36-1.2 15.43 15.43 0 0 1-3.8 3.92Z"
        style={{
          fill: "#0b133a",
        }}
      />
    </g>
  </svg>
);

export const LinkedInLogo = (props: JSX.IntrinsicElements["svg"]) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58.55 58.55" {...props}>
    <defs>{/* <style>{".cls-2{fill:#0b133a}"}</style> */}</defs>
    <g id="Layer_1" data-name="Layer 1">
      <circle
        cx={29.28}
        cy={29.28}
        r={29.28}
        style={{
          fill: "#fff",
        }}
      />
      <path
        style={{ fill: "#0b133a" }}
        className="cls-2"
        d="M37.63 23.08a11.14 11.14 0 0 1 3.76.56c2.5.91 3.7 2.86 4.26 5.33a22 22 0 0 1 .44 4.93v11.7c0 .39-.1.48-.49.48h-6.05c-.34 0-.42-.09-.42-.42V34.45A11.61 11.61 0 0 0 38.9 32a3.13 3.13 0 0 0-3.23-2.76 3.64 3.64 0 0 0-4.07 2.95 9.27 9.27 0 0 0-.28 2.35v11.08c0 .41-.13.5-.52.5h-6.05c-.34 0-.42-.1-.42-.42V24.11c0-.35.11-.44.45-.44h5.79c.35 0 .46.1.45.45v2.58l.11.05a7.32 7.32 0 0 1 6.49-3.63M20 34.84v10.77c0 .36-.08.47-.45.47h-6.1c-.32 0-.43-.07-.43-.41Q13 34.86 13 24c0-.35.12-.41.43-.4h6.1c.39 0 .45.14.45.48.02 3.61.02 7.18.02 10.76ZM16.5 20.55a4 4 0 1 1 4-4.07 4.06 4.06 0 0 1-4 4.07Z"
      />
    </g>
  </svg>
);
