import React, { ReactNode } from "react";
import { useSpring, animated, easings } from "react-spring";
import { useInView } from "react-intersection-observer";

// Todo - switch to css animation
export type AnimateDivProps = {
  children: ReactNode;
  animationType: string;
  fromTo: { from: number; to: number };
};

function AnimateDiv({ children, animationType, fromTo }: AnimateDivProps) {
  const slideIn = useSpring({
    from: { x: fromTo.from, opacity: 0 },
    to: { x: fromTo.to, opacity: 1 },
    config: {
      duration: 1200,
      easing: easings.easeOutExpo,
    },
    leave: {
      opacity: 0,
    },
  });

  const styles = animationType === "slideIn" ? slideIn : slideIn;
  return (
    <animated.div
      style={{
        ...styles,
      }}
    >
      {children}
    </animated.div>
  );
}

export type AnimateImageProps = {
  children: ReactNode;
  animationType: string;
  fromTo: { from: number; to: number };
};

export function AnimatedImage({
  children,
  animationType,
  fromTo,
}: AnimateImageProps) {
  const { ref, inView } = useInView({
    /* Optional options */
    rootMargin: "100% 0%",
    threshold: 0.1,
  });

  return (
    <div ref={ref}>
      {inView && (
        <AnimateDiv animationType={animationType} fromTo={fromTo}>
          <div className="">{children}</div>
        </AnimateDiv>
      )}
    </div>
  );
}
