import React, { useRef, useEffect } from "react";
import {
  ChartFlexboxContainer,
  useContainerDimensions,
} from "./scrollytelling-elements";
import * as d3 from "d3";
import { DataEntry, ReportDataEntry } from "./risk-report/data";

export type BarProps = {
  width: number;
  height: number;
  animate?: boolean;
  data: ReportDataEntry;
  numberOfElements: number;
  waffleArray: [];
};

export function BarComponent({
  data,
  className,
}: BarProps & JSX.IntrinsicElements["div"]) {
  const defaultMargin = { top: 20, right: 20, bottom: 20, left: 8 };
  const containerRef = useRef<HTMLDivElement>(null);
  const svgRef = useRef<SVGSVGElement>(null);
  const { width } = useContainerDimensions(containerRef);

  const dataLength = data && data.data.length;
  const barHeight = (width as number) < 500 ? 28 : 52;
  const barWidth = width ? width * 0.9 - defaultMargin.left : 0;
  const rx = 8;
  const barPadding = (width as number) < 500 ? 80 : 100;
  const transitionDuration = 500;
  const svgHeight = barHeight + barPadding * dataLength;

  function createNewBars(
    svgElem: d3.Selection<SVGSVGElement, unknown, null, undefined>
  ) {
    const outerG = svgElem.selectAll(".outerG");
    const dataEntry = (data && data.data) as DataEntry[];

    outerG.selectAll(".barGroup").data(dataEntry).exit().remove();

    const newBarGroups = outerG
      .selectAll(".barGroup")
      .data(dataEntry)
      .enter()
      .append("g")
      .attr("class", "barGroup")
      .attr("height", 100)
      .attr("transform", (d, i) => {
        return `translate(${0} ${i * barPadding})`;
      });

    newBarGroups
      .transition()
      .duration(transitionDuration * 2)
      .attr("transform", (d, i) => {
        return `translate(${0} ${i * barPadding})`;
      });

    newBarGroups
      .append("rect")
      .attr("rx", rx)
      .attr("stroke-width", 1)
      .attr("stroke", "rgba(0,0,0,0)")
      .attr("width", 0)
      .attr("height", barHeight)
      .attr("class", "rectFullWidth fill-lowlight-color-light");
    // .attr("width", barWidth);

    newBarGroups
      .append("rect")
      .attr("rx", rx)
      .attr("class", "barPercentOfTotal")
      .attr("height", barHeight)
      .attr("width", 0)
      .transition()
      .duration(transitionDuration * 2)
      .attr("width", (d) => {
        const convertToFloat =
          parseInt((d["Percent respondents"] as string).replace("%", "")) / 100;
        return barWidth * convertToFloat;
      })
      .attr("class", "barPercentOfTotal fill-highlight-color");

    const textGroupPercentUpdated = newBarGroups
      .append("g")
      .attr("class", "textGroupPercent")
      .attr("transform", () => {
        return `translate(${0} ${barHeight / 2 + 5})`;
      });

    textGroupPercentUpdated
      .transition()
      .duration(transitionDuration * 2)
      .attr("transform", (d) => {
        const convertToFloat =
          parseInt((d["Percent respondents"] as string).replace("%", "")) / 100;
        return `translate(${barWidth * convertToFloat + 5} ${
          barHeight / 2 + 5
        })`;
      });

    const textGroupAxisUpdated = newBarGroups
      .append("g")
      .attr("class", "textGroup")
      .attr("transform", () => {
        return `translate(${0} ${-4})`;
      });

    textGroupAxisUpdated
      .append("text")
      .text((d) => {
        return d.Answer as string;
      })
      .attr("class", "fill-medium-color absolute top-10 opacity-80");

    textGroupPercentUpdated
      .append("text")
      .text((d) => {
        return d["Percent respondents"] as string;
      })
      .attr(
        "class",
        "fill-highlight-color absolute top-10  font-SophiaProBold text-2xl"
      );
  }

  function updateExistingBars(
    svgElem: d3.Selection<SVGSVGElement, unknown, null, undefined>
  ) {
    svgElem.selectAll("text").attr("opacity", "100");
    const dataEntry = (data && data.data) as DataEntry[];

    svgElem
      .selectAll(".barPercentOfTotal")
      .data(dataEntry)
      .transition()
      .duration(1000)
      .attr("width", (d) => {
        const convertToFloat =
          parseInt((d["Percent respondents"] as string).replace("%", "")) / 100;

        return barWidth * convertToFloat;
      })
      .attr("class", "barPercentOfTotal fill-highlight-color");

    svgElem.selectAll(".rectFullWidth").transition().attr("width", barWidth);

    const textGroupPercent = svgElem
      .selectAll(".textGroupPercent")
      .data(dataEntry);

    const textGroupAxis = svgElem
      .selectAll(".textGroup")
      .data(dataEntry)
      .attr("transform", () => {
        return `translate(${0} ${-8})`;
      });

    textGroupAxis
      .select("text")
      .text((d) => {
        return d.Answer as string;
      })
      .attr("class", "fill-medium-color absolute top-10 opacity-100 mb-20");

    textGroupPercent
      .select("text")
      .text((d) => {
        return d["Percent respondents"] as string;
      })
      .attr(
        "class",
        "fill-highlight-color absolute top-10  font-SophiaProBold text-2xl"
      );

    textGroupPercent
      .transition()
      .duration(transitionDuration * 2)
      .attr("transform", (d) => {
        const convertToFloat =
          parseInt((d["Percent respondents"] as string).replace("%", "")) / 100;
        return `translate(${barWidth * convertToFloat + 5} ${
          barHeight / 2 + 5
        })`;
      });
  }

  function transitionBarsOut(
    svgElem: d3.Selection<SVGSVGElement, unknown, null, undefined>
  ) {
    svgElem
      .selectAll(".rectFullWidth")
      .transition()
      .duration(transitionDuration)
      .attr("width", 0);
    // .attr("opacity", 0);

    svgElem
      .selectAll(".barPercentOfTotal")
      .transition()
      .duration(transitionDuration)
      .attr("width", 0);
    // .attr("opacity", 0);

    svgElem.selectAll("text").attr("opacity", "0");
  }
  // First Load
  useEffect(() => {
    if (!svgRef.current || width === 0) {
      return;
    }
    const svg = d3.select(svgRef.current);

    createNewBars(svg);
  }, [svgRef]);

  // Update bars
  useEffect(() => {
    if (!svgRef.current || width === 0) {
      return;
    }
    const svg = d3.select(svgRef.current);
    createNewBars(svg);
    updateExistingBars(svg);
    data && data.item_id.includes("clear") && transitionBarsOut(svg);
  }, [data, width]);

  const scrollingStyles =
    "top-[12px] lg:top-[0vh] w-full max-w-[600px] relative flex flex-wrap items-center justify-center";

  return (
    <ChartFlexboxContainer className={className}>
      <div className={scrollingStyles} ref={containerRef}>
        <div className={"mt-0px"}>
          <div
            style={{ width: width ? width * 0.9 : 0 }}
            className="pb-10 text-xl font-semibold text-medium-color"
          >
            {`${data && data.highlightTitle}`}
          </div>
        </div>
        <svg
          height={svgHeight}
          width={width ? width * 0.9 : 0}
          ref={svgRef}
          role={"img"}
        >
          <title>{`${data && data.highlightTitle} `}</title>

          <g
            transform={`translate(${0} ${defaultMargin.top})`}
            className={"outerG"}
          ></g>
        </svg>
      </div>
    </ChartFlexboxContainer>
  );
}
