import React from "react";
import { ReportDataEntry } from "./data";

export function IntroText({ children }: JSX.IntrinsicElements["div"]) {
  return (
    <div>
      <div className="max-w-screen-sm mb-0 text-5xl text-bright-text">
        {children}
      </div>
    </div>
  );
}

export function Highlight({ children }: JSX.IntrinsicElements["div"]) {
  // return <span className='p-2 bg-yellow-200 text-slate-700'>{children}</span>
  return <span className="">{children}</span>;
}

export function IntroBullets() {
  return (
    <div className="text-bright-text ">
      <div className="mb-10 text-2xl ">
        The findings led us to 4 key conclusions:
      </div>
      <ul className="pl-4 text-2xl list-disc list-inside">
        <li className="pb-5 s">
          Global Supply Chains are Being{" "}
          <Highlight>Reconfigured in Response to Disruptive Events</Highlight>
        </li>
        <li className="pb-5">
          Supply Chain Disruptions are Frequent, Expensive and Often Hidden from
          View
        </li>
        <li className="pb-5">
          Supply Chain Risk Practices Require Improvement
        </li>
        <li className="pb-5">
          Technology Has a Vital Role to Play in Managing Risk Proactively
        </li>
      </ul>
    </div>
  );
}

export function LargeTitle({ children }: JSX.IntrinsicElements["div"]) {
  return (
    <div className="relative flex justify-center w-full text-2xl lg:leading-normal lg:text-4xl">
      {children}
    </div>
  );
}

export function TextSeperator() {
  return (
    <div className="flex">
      <div className="w-3/12"></div>
      <div className="w-6/12 h-[1px] bg-medium-color m-0  " />
      <div className="w-3/12"></div>
    </div>
  );
}

export function LargeTitleCenterPage({
  children,
}: JSX.IntrinsicElements["div"]) {
  return (
    <div>
      <div className="flex items-center">
        <div className="w-1/12"></div>
        <div className="w-10/12 mt-0 text-center font-light text-3xl mb-[30px] h-1/8 text-medium-color">
          {children}
        </div>
        <div className="w-1/12"></div>
      </div>
      <TextSeperator></TextSeperator>
    </div>
  );
}

export function SideTextContainer({ children }: JSX.IntrinsicElements["div"]) {
  return (
    <div className="pb-[90vh] md:pb-[50vh] lg:max-w-screen-sm w-10/12 z-50">
      {children}
    </div>
  );
}

export function SideParagraph({ children }: JSX.IntrinsicElements["div"]) {
  return (
    <div className="pt-8 ">
      <LargeTitle>{children}</LargeTitle>
    </div>
  );
}

interface SideTextTypeOneProps {
  paragraphs: string[];
  largeTitle: string;
}
export function SideTextTypeOne({
  paragraphs,
  largeTitle,
}: SideTextTypeOneProps) {
  if (largeTitle.includes("clear")) {
    return <div className=" w-full z-50 opacity-1 m-0 h-[20px]"></div>;
  }
  return (
    <SideTextContainer>
      <div className="p-4 border-2 rounded-lg lg:border-0 lg:rounded-2xl border-xs bg-lighter-bg/80 lg:bg-lighter-bg/0 backdrop-blur-sm border-lowlight-color-light">
        <LargeTitle>
          <div dangerouslySetInnerHTML={{ __html: largeTitle }}>
            {/* {largeTitle} */}
          </div>
        </LargeTitle>
        {paragraphs.map((paragraph: string, index) => (
          <SideParagraph key={index}>
            <div dangerouslySetInnerHTML={{ __html: paragraph }}></div>
          </SideParagraph>
        ))}
      </div>
    </SideTextContainer>
  );
}

interface AnnotationTextProps {
  containerWidth: number;
  data: ReportDataEntry;
  waffleWidth: number;
  sumPercentHighlights: number;
  responsivePosition: number;
  top: number;
}
export function AnnotationText({
  containerWidth,
  data,
  waffleWidth,
  sumPercentHighlights,
  responsivePosition,
  top,
}: AnnotationTextProps) {
  const leftWide = responsivePosition + waffleWidth + 10;
  const leftNarrow = responsivePosition - 16;
  const responsiveTextLeft = containerWidth < 800 ? leftNarrow : leftWide;
  const percentCondition =
    data !== 0 && data.item_id != "waffle-blank"
      ? `${sumPercentHighlights}% `
      : "";

  return data !== 0 && data.highlightTitle ? (
    <div
      style={{ left: responsiveTextLeft, top: top }}
      className="absolute w-3/4 ml-5 text-xl md:w-[120px] lg:w-[200px] text-bright-text"
    >
      <span className="text-4xl font-bold border-0 opacity-100 text-highlight-bright-color font-SophiaProBold">
        {percentCondition}
      </span>
      {`${data.highlightTitle}`}
    </div>
  ) : null;
}

export function ParagraphBlock({
  children,
  className,
}: JSX.IntrinsicElements["div"]) {
  return (
    <div
      className={"pl-8 pr-8 pt-8 max-w-2xl m-auto article-text " + className}
    >
      {children}
    </div>
  );
}

export function SingleParagraph({ children }: JSX.IntrinsicElements["div"]) {
  return <div className="pb-8 text-xl lg:leading-normal ">{children}</div>;
}

export function FeaturedImageInnerText({
  children,
}: JSX.IntrinsicElements["div"]) {
  return (
    <div className="flex flex-wrap content-center justify-center w-full lg:justify-end lg:w-1/2">
      {/* Inner layout flex */}
      <div className="w-full max-w-xl pl-4 pr-4 xl:pl-0 lg:pr-0 lg:w-full">
        {children}
      </div>
    </div>
  );
}

export function FeaturedImageFlex({ children }: JSX.IntrinsicElements["div"]) {
  return (
    <div
      className={"pl-0 pr-0 pt-8 2xl:pl-0 xl:pr-0 lg:pt-0 lg:flex flex-row "}
    >
      {children}
    </div>
  );
}
