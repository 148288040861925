import { useState } from "react";
import Cookies, { CookieSetOptions } from "universal-cookie";

export const useCookie = (key: string) => {
  const cookies = new Cookies();
  const [cookie, setCookie] = useState(() => {
    if (cookies.get(key)) {
      return cookies.get(key);
    }
  });

  const updateCookie = (value: string, options: CookieSetOptions) => {
    setCookie(value);
    removeItem(value);
    cookies.set(key, value, options);
  };

  const removeItem = (key: string) => {
    cookies.remove(key);
  };

  return [cookie, updateCookie, removeItem];
};
