import * as React from "react";

const DownloadSvg = (props: JSX.IntrinsicElements["svg"]) => (
  <svg
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16.31 21.44"
    {...props}
  >
    {/* <title>Download</title> */}
    <defs>
      <style>{".cls-1{fill:#fff}"}</style>
    </defs>
    <g id="Layer_1" data-name="Layer 1">
      <path d="M7.45 15.45a1 1 0 0 0 .7.3 1 1 0 0 0 .71-.3L15.29 9a1 1 0 1 0-1.41-1.41l-4.73 4.74V1a1 1 0 0 0-2 0v11.33L2.43 7.61a1 1 0 0 0-1.43 0A1 1 0 0 0 1 9ZM15.31 19.44H1a1 1 0 0 0 0 2h14.31a1 1 0 0 0 0-2Z" />
    </g>
  </svg>
);

export default DownloadSvg;
