import React, { useRef, useEffect, useMemo } from "react";
import {
  checkBarLayoutHighlights,
  getPercentHighlights,
  windowHeight,
  useContainerDimensions,
} from "../components/scrollytelling-elements";
import { AnnotationText } from "../components/risk-report/scrollytelling-text";
import * as d3 from "d3";
import { DataEntry, ReportDataEntry } from "./risk-report/data";

const defaultMargin = { top: 20, right: 20, bottom: 20, left: 20 };

export type WaffleProps = {
  width: number;
  height: number;
  margin?: typeof defaultMargin;
  animate?: boolean;
  data: ReportDataEntry;
  progress: ReportDataEntry[];
  numberOfElements: number;
  waffleArray: number[];
};

export function WaffleComponent({
  data,
  numberOfElements,
  waffleArray,
}: WaffleProps) {
  const componentRef = useRef<HTMLDivElement>(null);
  const svgRef = useRef<SVGSVGElement>(null);
  const gRef = useRef<SVGGElement>(null);
  const { width } = useContainerDimensions(componentRef);

  const highlightTitle = useMemo(
    () => (data ? data.highlightTitle : ""),
    [data]
  );

  function chunk(arr: [], chunkSize: number) {
    if (chunkSize <= 0) throw "Invalid chunk size";
    const R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize)
      R.push(arr.slice(i, i + chunkSize));

    return R;
  }

  function chunkBars(arr: number[], chunkSize: number) {
    if (chunkSize <= 0) throw "Invalid chunk size";
    const R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize)
      R.push(arr.slice(i, i + chunkSize));

    return R;
  }

  const containerWidth = width as number;
  const waffleWidth =
    containerWidth < 500
      ? containerWidth - 30
      : containerWidth > 500 && containerWidth < 800
      ? 400
      : numberOfElements === 100
      ? 350
      : 500;
  const totalHeight = windowHeight;
  const rowElements = numberOfElements === 100 ? 10 : 40;
  const borderSize = 2;
  const cellSize = waffleWidth / rowElements - 4 + borderSize;
  const cellWithPadding = cellSize + borderSize;
  const margin = { left: 8, top: 50, right: 200, bottom: 50 };
  const groupedData = chunk(waffleArray as [], rowElements as number);
  const { sumPercentHighlights, percentToHighlight, percentOfTotal } =
    getPercentHighlights(data, numberOfElements);
  const { isBarLayout, barLayoutData } = checkBarLayoutHighlights(data);

  // Update waffle graphic while scrolling.
  useEffect(() => {
    if (!gRef.current || isBarLayout) {
      return;
    }

    d3.selectAll(".animatedRect")
      .attr("width", cellSize)
      .attr("height", cellSize);
    const gContainer = d3.select(gRef.current);

    const rectContainers = gContainer
      .selectAll("g")
      .data(groupedData.reverse())
      .attr(
        "transform",
        (_d, i: number) => `translate(${margin.left} ${i * cellWithPadding})`
      );

    rectContainers
      .selectAll("rect")
      .attr("width", cellSize)
      .attr("height", cellSize)
      .attr("opacity", 1)
      .data((d: number[]) => d)
      .transition()
      .attr("class", (d) =>
        d < percentOfTotal
          ? "animatedRect fill-highlight-color"
          : "animatedRect fill-lowlight-color-heavy"
      )
      .transition()
      .ease(d3.easeBounce)
      .duration((d) => (d + 1000) * Math.random())
      .attr("y", (d) => (d < percentOfTotal ? 50 : 0))
      .attr("x", (d, cellRowIndex) => cellRowIndex * cellWithPadding);
  }, [percentOfTotal, waffleWidth, groupedData]);

  //Before/after bar layout transition only for the ukraine at this stage
  //TODO - merge with broader bar layout
  useEffect(() => {
    if (!gRef.current || !isBarLayout) {
      return;
    }

    const barWidth = 20;
    const smallerRect = 5;
    const selectedAnswers = (data &&
      data.data.filter(
        (d: DataEntry) => d.Response && d.Response.includes("Geopolitical")
      )) as DataEntry[];

    const afterNumElements =
      (parseInt(
        selectedAnswers[0][
          "Percent respondents after crisis in Ukraine"
        ]?.replace("%", "") as string
      ) /
        100) *
      numberOfElements;

    const beforeNumElements =
      (parseInt(
        selectedAnswers[0][
          "Percent respondents before crisis in Ukraine"
        ]?.replace("%", "") as string
      ) /
        100) *
      numberOfElements;
    const beforeData = chunkBars(
      Array.from({ length: beforeNumElements }, (v, i) => i),
      barWidth
    );
    const afterData = chunkBars(
      Array.from({ length: afterNumElements }, (v, i) => i),
      barWidth
    );
    // let groupedBarData = chunkBars(waffleArray as [], 20)
    const gContainer = d3.select(gRef.current);

    gContainer.selectAll("g").data(beforeData.reverse());
    // .attr("transform", (d: {}, i: number) => `translate(${0} ${i * (smallerRect + 1)})`)

    const rectContainersAfter = gContainer
      .selectAll("g")
      .attr(
        "transform",
        (_d, i: number) => `translate(${0} ${i * (smallerRect + 1)})`
      )
      .data(afterData.reverse());

    rectContainersAfter
      .selectAll(".animatedRect")
      .data((d) => d)
      .attr("class", "afterData")
      .attr("width", smallerRect)
      .attr("height", smallerRect)
      .transition()
      .duration(1000)
      .attr("fill", "white")
      .attr(
        "x",
        (_d, cellRowIndex: number) => cellRowIndex * (smallerRect + 1) + 200
      )
      .attr("y", () => 0);

    gContainer.selectAll(".animatedRect").attr("x", 2000).attr("opacity", 0);
  }, [barLayoutData, groupedData, gRef]);

  //Render graphic on first load
  useEffect(() => {
    if (!gRef.current) {
      return;
    }
    if (!groupedData) {
      return;
    }

    const gContainer = d3.select(gRef.current);

    const rectContainers = gContainer
      .selectAll("g")
      .data(groupedData)
      .enter()
      .append("g")
      .attr(
        "transform",
        (d: number[], i: number) => `translate(${0} ${i * cellWithPadding})`
      );

    rectContainers
      .selectAll("rect")
      .data((d) => d)
      .enter()
      .append("rect")
      .attr("opacity", 1)
      .attr("class", (d) =>
        d < percentOfTotal
          ? "animatedRect fill-highlight-color"
          : "animatedRect fill-lowlight-color-heavy"
      )
      .attr("width", cellSize)
      .attr("height", cellSize)
      .transition()
      .ease(d3.easeCubic)
      .duration(1000)
      .delay((d) => d * Math.random())
      .attr("y", (d) => (d < percentOfTotal ? 50 : 0))
      .attr("x", (_d, cellRowIndex) => cellRowIndex * cellWithPadding);
  }, [gRef, groupedData]);

  // Manually doing justifcation instead of using tailwind because SVG needs to extend beyond g for animated elements flying in.
  const leftJustifyCenter = (containerWidth - waffleWidth + borderSize) / 2.2;
  const leftJustifyRight = leftJustifyCenter + leftJustifyCenter / 1.5;
  const responsivePosition =
    containerWidth < 1020 ? leftJustifyCenter : leftJustifyRight;
  const topJustify = totalHeight / 5;
  const textTopWide =
    topJustify +
    waffleWidth -
    waffleWidth * ((percentToHighlight as number) / 100) +
    50;
  const textTopNarrow = topJustify + waffleWidth + 50;
  const textTopResponsive = containerWidth < 800 ? textTopNarrow : textTopWide;

  return (
    <div className="w-full " ref={componentRef}>
      {/* { containerWidth && <div style={ { left: responsivePosition, top: topJustify - 50 } } className="absolute text-2xl font-light align-middle text-medium-color">1500 Executives</div> } */}

      <AnnotationText
        top={textTopResponsive}
        containerWidth={containerWidth}
        waffleWidth={waffleWidth}
        sumPercentHighlights={sumPercentHighlights as number}
        data={data}
        responsivePosition={responsivePosition}
      />
      {containerWidth && (
        <svg
          ref={svgRef}
          className="w-full"
          width={waffleWidth + margin.left + margin.right + borderSize}
          height={totalHeight}
          style={{ marginTop: 0 }}
          role={"img"}
        >
          <title>{`${percentToHighlight}% ${highlightTitle} `}</title>
          <g
            ref={gRef}
            width={waffleWidth}
            transform={`translate(${responsivePosition} ${topJustify})`}
          ></g>
        </svg>
      )}
    </div>
  );
}
