import React from "react";
import { useCookie } from "../../utils/use-cookie";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import { useLocation } from "@reach/router";

interface CookieConsentProps {
  onInteraction: () => void;
}
export const CookieConsent = ({ onInteraction }: CookieConsentProps) => {
  const [, updateCookie] = useCookie("gatsby-gdpr-google-analytics");
  const location = useLocation();

  return (
    <div className="fixed py-4 px-8 min-h-[100px] flex flex-col md:flex-row gap-4 justify-center items-center bottom-[30px] md:bottom-0 w-full bg-[#FFFFFF] z-[1000]">
      <p>
        By clicking “Accept”, you agree to the storing of cookies on your device
        to enhance site navigation and analyze site usage. To learn more about
        the cookies we use, please view our{" "}
        <a
          className="text-highlight-color"
          target="_blank"
          href="https://www.interos.ai/privacy-policy/"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
        .
      </p>
      <div className="flex gap-2 text-sm">
        <button
          onClick={() => {
            updateCookie("false");
            onInteraction();
          }}
          className="h-12 py-2 px-4 rounded-full border border-gray-dark"
        >
          Reject
        </button>
        <button
          onClick={() => {
            updateCookie("true");
            initializeAndTrack(location);
            onInteraction();
          }}
          className="h-12 py-2 px-4 rounded-full bg-highlight-color text-lowlight-color-heavy"
        >
          Accept
        </button>
      </div>
    </div>
  );
};
